import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'
import { observer } from 'mobx-react-lite'
import { SelectRoles } from '../../components'
import { toJS } from 'mobx'
import { StoreContext } from '../../store'
import styled from 'styled-components'
import { IRoleGroupModel } from '../../store/dataModels/interfaces'

type Props = RouteComponentProps

const FilterServicesListComponent: React.FC<Props> = () => {
  const { searchStore } = useContext(StoreContext)

  const { organizations, selectedOrganisations, selectedRoles, toggleSelectedRole } = searchStore

  const [t] = useTranslation()

  let roleGroups: IRoleGroupModel[] = Object.values(
    toJS(selectedOrganisations) // Start with a list of selected dropdown options
      .filter(org => org.value !== '*') // filter out "all organizations" value from dropdown
      .map(org => toJS(organizations.find(orgz => orgz.businessId === org.value))) // Map dropdown options to actual organizations
      .reduce((acc, it) => acc.concat(it.roleGroups), []) // Combine all rolegroups from all organizations in a single list
      .reduce((acc, it) => {
        // Combine duplicate rolegroups together
        // - If rolegroup is not yet added, take it as it is
        // - Otherwise ensure that each role is included in the rolegroup only once
        // This results in an associative array, which is converted to a normal array by the wrapping "Object.values"-method
        if (!acc[it.value]) {
          acc[it.value] = it
        } else {
          let missingRoles = it.roles.filter(r => !acc[it.value].roles.some(existing => existing.value == r.value))
          acc[it.value].roles.concat(missingRoles)
        }
        return acc
      }, [])
  )

  return (
    <>
      <StyledFilterByService>{t('filter.byService')}</StyledFilterByService>
      <SelectRoles
        roleGroups={roleGroups}
        selectedRoleValues={selectedRoles}
        toggleUserRole={role => toggleSelectedRole(role)}
        filterStyles
      />
    </>
  )
}

export const FilterServicesList = withRouter(observer(FilterServicesListComponent))

const StyledFilterByService = styled('div')`
  margin-top: 24px;
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #262c30;
  margin-bottom: 24px;
`
