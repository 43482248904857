import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { CheckmarkCircledIcon, ProfileIcon, Headline } from '@postidigital/posti-components'
import Theme from '@postidigital/posti-theme'
import { StoreContext } from '../../store'
import styled, { css } from 'styled-components'

const InfoBoxComponent: React.FC<{}> = ({}) => {
  const { inviteStore } = useContext(StoreContext)

  const { emailList, selectedBusiness } = inviteStore
  const { name, businessId } = selectedBusiness

  return (
    <StyledInfoBox>
      <StyledContentWrapper>
        <StyledHeadline as="div" size="Six">
          {`${name} (${businessId})`}
        </StyledHeadline>

        {emailList.map(email => {
          return (
            <StyledText key={email}>
              <StyledProfileIcon />
              {email}
            </StyledText>
          )
        })}
      </StyledContentWrapper>
      <StyledIconWrapper>
        <CheckmarkCircledIcon color={Theme.color.signalBlue} height="1.5rem" />
      </StyledIconWrapper>
    </StyledInfoBox>
  )
}

export const InfoBox = observer(InfoBoxComponent)

const StyledHeadline = styled(Headline)`
  margin-right: 8px;
  margin-bottom: 16px;
`

const StyledProfileIcon = styled(ProfileIcon)`
  margin-right: 8px;
`

const StyledInfoBox = styled('div')`
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.color.signalBlue};
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
`

const StyledIconWrapper = styled('div')`
  margin: 16px;
  margin-left: auto;
`

const StyledContentWrapper = styled('div')`
  margin: 16px 16px 16px 16px;
`

const StyledText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.primary};

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 143%;

  display: flex;
  align-items: center;

  color: #000000;
`
