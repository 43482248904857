import React from 'react'
import styled, { css } from 'styled-components'

import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'
import { Button } from '@postidigital/posti-components'
import { getCurrentBasePath } from '../../utils/helpers'
import ErrorLarge from '../../static/img/error_large'

enum Errors {
  general = 'general',
  badUrl = 'badUrl',
}

interface IProps {
  error: keyof typeof Errors
}

type Props = RouteComponentProps & IProps

export const ErrorComponentComponent: React.FC<Props> = ({ error, history }) => {
  const { t, i18n } = useTranslation()
  return (
    <StyledErrorContainer>
      <ErrorLarge />
      <StyledTitle>{t(`errors.${error}.title`)}</StyledTitle>
      {error === 'badUrl' && <StyledBodyText>{t(`errors.${error}.body`)}</StyledBodyText>}
      <StyledButton
        css
        title={t(`errors.reload`)}
        onClick={() => {
          const basePath = getCurrentBasePath(i18n)
          history.push(`${basePath}/`)
          window.location.reload()
        }}
      >
        {t(`errors.refresh`)}
      </StyledButton>
    </StyledErrorContainer>
  )
}

export const ErrorComponent = withRouter(ErrorComponentComponent)

const StyledButton = styled(Button)`
  margin-top: 32px;
`

const StyledErrorContainer = styled.div(
  ({ theme }) => css`
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 48px;
  `
)

const StyledTitle = styled.div(
  ({ theme }) => css`
    font-family: PostiSans;
    font-style: normal;
    font-weight: 500;
    font-size: 39px;
    line-height: 40px;
    letter-spacing: -0.01em;

    color: #000000;
    margin-top: 16px;

    text-align: center;
  `
)

const StyledBodyText = styled.div(
  ({ theme }) => css`
    font-family: ${theme.fontFamily.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    text-align: center;
    margin-top: 8px;
  `
)
