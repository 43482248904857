import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'
import { observer } from 'mobx-react-lite'
import { SearchAndFilter, InviteList, LandingUsers, Filters, StyledButton } from '../components'
import { Loading, Headline, DownloadIcon } from '@postidigital/posti-components'
import { StoreContext } from '../store'
import styled from 'styled-components'
import { breakpoint } from '../utils/breakpoint'
import Theme from '@postidigital/posti-theme'

type Props = RouteComponentProps

const MainViewComponent: React.FC<Props> = () => {
  const [t] = useTranslation()
  const { environmentStore, searchStore, layoutStore } = useContext(StoreContext)
  const { invites, isSearching, isFetchingCsv, fetchCsv } = searchStore

  return (
    <StyledWrapper>
      <StyledContent>
        <StyledMainTitle as="h1" size="Four" color={Theme.color.neutralBlack}>
          {t(`landing.userManagement`)}
        </StyledMainTitle>

        <StyledDivider />

        <SearchAndFilter />

        <StyledHeadline as="div" size="Five" color="#000000">
          {t(`landing.invitations`)}
        </StyledHeadline>

        {isSearching ? (
          <StyledCenter>
            <Loading preset="brandPink" size="md" />
          </StyledCenter>
        ) : (
          <InviteList invites={invites} />
        )}

        <StyledUsersHeadline as="div" size="Five" color="#000000">
          {t(`general.users`)}
          {environmentStore.hasFeature('csvDownload') && !isSearching && (
            <StyledButton
              id="download_csv_button"
              disabled={isFetchingCsv}
              mode="secondary"
              hasBackground={false}
              onClick={() => fetchCsv()}
              icon={isFetchingCsv ? Loading : DownloadIcon}
              iconPosition="right"
            >
              {t(`landing.download`)}
            </StyledButton>
          )}
        </StyledUsersHeadline>

        {isSearching ? (
          <StyledCenter>
            <Loading preset="brandPink" size="md" />
          </StyledCenter>
        ) : (
          <LandingUsers />
        )}
      </StyledContent>
      {layoutStore.isDesktop && <StyledSepparator />}
      {layoutStore.isDesktop && <Filters />}
    </StyledWrapper>
  )
}

export const MainView = withRouter(observer(MainViewComponent))

const StyledCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`

const StyledDivider = styled.div`
  display: none;
  @media ${breakpoint.mobile} {
    display: block;
    height: 2px;
    background: #e6ebf1;
    width: 100%;
    margin-bottom: 16px;
  }
`

const StyledMainTitle = styled(Headline)`
  margin-bottom 24px;
  @media ${breakpoint.mobile} {
    margin-bottom 16px;
    margin-left: 16px;
  }
`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
`

const StyledHeadline = styled(Headline)`
  margin-top: 40px;
  margin-bottom: 24px;
  @media ${breakpoint.mobile} {
    margin-left: 16px;
  }
`

const StyledUsersHeadline = styled(StyledHeadline)`
  display: flex;
  justify-content: space-between;
  button {
    margin-right: -0.5rem;
    background: none;
    span:first-of-type {
      padding: 1rem 0.5rem;
    }
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
`

const StyledContent = styled('div')`
  flex-grow: 3;
  padding-top: ${({ theme }) => theme.spacing.lg}rem;
  padding-right: ${({ theme }) => theme.spacing.lg}rem;
  @media ${breakpoint.sm} {
    padding-right: ${({ theme }) => theme.spacing.sm}rem;
  }
  @media ${breakpoint.mobile} {
    padding-top: ${({ theme }) => theme.spacing.md}rem;
    padding-right: 0px;
  }
`

const StyledSepparator = styled('div')`
  width: 16px;
  background: ${({ theme }) => theme.color.neutralGray2};
  box-shadow: inset 0px 4px -4px rgba(0, 0, 0, 0.25);
`
