import React from 'react'
import styled from 'styled-components'
import { Loading } from '@postidigital/posti-components'

export const ViewLoading: React.FC<{}> = () => {
  return (
    <StyledCenter>
      <Loading preset="brandPink" size="xl" />
    </StyledCenter>
  )
}

const StyledCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35%;
`
