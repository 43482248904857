import React, { useContext, useEffect } from 'react'
import { TransProps, useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'
import { observer } from 'mobx-react-lite'
import { MainTitle, OrganisationExpander, ViewLoading, StyledButton } from '../components'
import { ListItem, Button, Notification } from '@postidigital/posti-components'
import { StoreContext } from '../store'
import styled from 'styled-components'
import { formatDate, getInviteState } from '../utils'

interface ReactRouterIdProp {
  id: string
}

type Props = Partial<TransProps> & RouteComponentProps<ReactRouterIdProp>

const InviteDetailsViewComponent: React.FC<Props> = ({ match }) => {
  const [t] = useTranslation()
  const { inviteDetailsStore } = useContext(StoreContext)
  const inviteId = match.params.id

  const {
    isLoading,
    inviteDetail,
    fetchingStage,
    selectedRoleValues,
    selectedCustomerNumbers,
    selectedLCNumbers,
    selectedTransportIds,
    selectedBusinessPartnerNumbers,
  } = inviteDetailsStore

  useEffect(() => {
    inviteDetailsStore.fetchInviteData(inviteId)
  }, [])

  if (isLoading) {
    return <ViewLoading />
  }

  const { email, created, expires, expired, cancelled, accepted } = inviteDetail

  return (
    <>
      <MainTitle>{t(`invite.inviteDetailsTitle`)}</MainTitle>
      <StyledDetailsWrapper>
        <StyledListItem
          id="invite_details_created_at"
          title={formatDate(created)}
          subtitle={t(`general.created`)}
          size="md"
        />
        <StyledListItem id="invite_details_email" title={email} subtitle={t(`invite.userUsername`)} size="md" />
        <StyledListItem
          id="invite_details_state"
          title={getInviteState(cancelled, expired, expires, accepted, t)}
          subtitle={t(`general.valid`)}
          size="md"
        />
      </StyledDetailsWrapper>
      <StyledNotiContainer>
        {fetchingStage && (
          <Notification type={getNotiType(fetchingStage)} children={<span>{getNotiText(fetchingStage, t)}</span>} />
        )}
      </StyledNotiContainer>

      <StyledButtonsWrapper>
        <StyledButton
          disabled={Boolean(accepted)}
          id="resend_button"
          onClick={() => inviteDetailsStore.resendInvite(inviteId)}
          mode="secondary"
          hasBackground={false}
          size="sm"
        >
          {t(`general.resend`)}
        </StyledButton>
        <StyledButton
          disabled={Boolean(accepted || cancelled || expired)}
          id="cancel_button"
          onClick={() => inviteDetailsStore.cancelInvite(inviteId)}
          mode="secondary"
          hasBackground={false}
          size="sm"
        >
          {t(`general.cancel`)}
        </StyledButton>
      </StyledButtonsWrapper>
      <OrganisationExpander
        organization={inviteDetail.organization}
        selectedRoleValues={selectedRoleValues}
        selectedCustomerNumbers={selectedCustomerNumbers}
        selectedLCNumbers={selectedLCNumbers}
        selectedTransportIds={selectedTransportIds}
        selectedBusinessPartnerNumbers={selectedBusinessPartnerNumbers}
      />
    </>
  )
}

export const InviteDetailsView = withRouter(observer(InviteDetailsViewComponent))

function getNotiType(fetchingStage: string) {
  switch (fetchingStage) {
    case 'fetching':
      return 'warning'
    case 'resent':
      return 'success'
    case 'cancelled':
      return 'success'
  }
}

function getNotiText(fetchingStage: string, t: (key: string) => string) {
  switch (fetchingStage) {
    case 'fetching':
      return t(`general.fetching`)
    case 'resent':
      return t(`general.sent`)
    case 'cancelled':
      return t(`general.cancelled`)
  }
}

const StyledDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
`
const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`

const StyledListItem = styled(ListItem)`
  width: 50%;
`

const StyledNotiContainer = styled.div`
  height: 80px;
`
