import React, { useContext } from 'react'
import styled from 'styled-components'

import { TransProps, useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'
import { observer } from 'mobx-react-lite'
import { Button, Search, PlusIcon, CloseIcon, ChevronUpIcon, Headline } from '@postidigital/posti-components'
import Theme from '@postidigital/posti-theme'
import { debounce, StoreContext } from '../../store'
import { getCurrentBasePath } from '../../utils/helpers'
import { breakpoint } from '../../utils/breakpoint'
import Popup from 'reactjs-popup'
import { MainUserRadioGroup, FilterDropdown, FilterServicesList } from '../../components'
import { toJS } from 'mobx'
import { arrayIsEmpty } from '../../utils/helpers'
import { FilterButton } from '../buttons/buttons'
import { DropDownType } from '../../store/dataModels/interfaces'

type Props = Partial<TransProps> & RouteComponentProps

const SearchAndFilterComponent: React.FC<Props> = ({ history }) => {
  const { t, i18n } = useTranslation()
  const { layoutStore, searchStore } = useContext(StoreContext)
  const { isMobile } = layoutStore

  const {
    searchTerm,
    organizations,
    searchMainUsers,
    organisationsOptions,
    selectedOrganisations,
    customerNumbers,
    selectedCustomerNumbers,
    LSCN,
    selectedLSCN,
    selectedRoles,
    transportIds,
    selectedTransportIds,
    toggleSearchMainUsers,
    setSelectedOrganisations,
    setSelectedCustomerNumbers,
    setSelectedLSCN,
    setSelectedTransportIds,
    toggleSelectedRole,
    clearFilters,
    setSearchTerm,
  } = searchStore

  const onButtonClick = () => {
    const basePath = getCurrentBasePath(i18n)
    history.push(`${basePath}/invite/1`)
  }

  const allOption = {
    label: t(`general.selectAll`),
    value: '*',
    type: DropDownType.SELECT_ALL,
  }

  const orgsToJS = toJS(organisationsOptions)
  const selectedOrganisationsToJS = toJS(selectedOrganisations)

  const orgsSelected = !arrayIsEmpty(selectedOrganisationsToJS)

  const filtersSelected = Boolean(orgsSelected || searchMainUsers)

  return (
    <div>
      <StyledBarAndInviteWrapper>
        {isMobile && (
          <StyledPopup
            modal
            closeOnDocumentClick
            contentStyle={contentStyle}
            overlayStyle={overlayStyle}
            trigger={open => <FilterButton selected={filtersSelected} onClick={open} />}
          >
            {close => (
              <StyledModalContent>
                <StyledClearAll>
                  <Headline onClick={() => clearFilters()} as="div" size="Six" color={Theme.color.signalBlue}>
                    {t('filter.clearAll')}
                  </Headline>
                  <CloseIcon fontSize={'10px'} color={Theme.color.signalBlue} onClick={() => clearFilters()} />
                </StyledClearAll>

                <MainUserRadioGroup
                  small
                  plurarTranslations
                  isMainUser={searchMainUsers}
                  toggleUserRole={() => toggleSearchMainUsers()}
                />

                <FilterDropdown
                  id="organization_selector"
                  options={orgsToJS}
                  selectedOptions={selectedOrganisationsToJS}
                  allowSelectAll
                  isMulti
                  allOption={allOption}
                  placeHolderText={t('general.organisations')}
                  onChange={values => setSelectedOrganisations(values)}
                />

                <FilterDropdown
                  id="customer_number_selector"
                  disabled={!orgsSelected}
                  options={toJS(customerNumbers)}
                  selectedOptions={toJS(selectedCustomerNumbers)}
                  allowSelectAll
                  isMulti
                  allOption={allOption}
                  placeHolderText={t('general.customerNumbers')}
                  onChange={values => setSelectedCustomerNumbers(values)}
                />

                <FilterDropdown
                  id="lcsnumbers_selector"
                  disabled={!orgsSelected}
                  options={toJS(LSCN)}
                  selectedOptions={toJS(selectedLSCN)}
                  allowSelectAll
                  isMulti
                  allOption={allOption}
                  placeHolderText={t('general.LCSNumbers')}
                  onChange={values => setSelectedLSCN(values)}
                />

                <FilterDropdown
                  id="transportid_selector"
                  disabled={!orgsSelected}
                  grouped
                  options={transportIds}
                  selectedOptions={toJS(selectedTransportIds)}
                  allowSelectAll
                  isMulti
                  allOption={allOption}
                  placeHolderText={t('general.transportIds')}
                  onChange={values => setSelectedTransportIds(values)}
                />

                {orgsSelected && <FilterServicesList />}

                <StyledButton2 id="btn_close_filter" onClick={() => close()}>
                  <ChevronUpIcon fontSize={'14px'} color={Theme.color.neutralWhite} />
                  {t('filter.done')}
                </StyledButton2>
              </StyledModalContent>
            )}
          </StyledPopup>
        )}

        <StyledSearch
          onChange={e => {
            e.preventDefault()

            if (!e?.target?.value) {
              return setSearchTerm('')
            }

            setSearchTerm(e.target.value)

            // TODO: This custom debounce should be removed and contents moved
            // to onChangeWithDebounce once Search component has been fixed
            debounce(searchStore.debouncedFetchResults, 1000, false)
          }}
          value={searchTerm}
          label={t(`landing.search`)}
          id="search-1"
          clearButtonProps={{
            id: 'search_1_clear_button',
            onClick: () => setSearchTerm(''),
          }}
        />

        <StyledBreakFlex />

        <StyledButton
          css
          size={isMobile ? 'sm' : 'md'}
          mode={isMobile ? 'secondary' : 'primary'}
          hasBackground={!isMobile}
          onClick={onButtonClick}
        >
          <StyledPlusIcon color={isMobile ? Theme.color.signalBlue : Theme.color.neutralWhite} height="1.5em" />
          <Headline as="div" size="Six" color={isMobile ? Theme.color.signalBlue : Theme.color.neutralWhite}>
            {t(`landing.inviteUser`)}
          </Headline>
        </StyledButton>
      </StyledBarAndInviteWrapper>
    </div>
  )
}

export const SearchAndFilter = withRouter(observer(SearchAndFilterComponent))

const StyledBarAndInviteWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  margin-top: -16px;

  @media ${breakpoint.mobile} {
    order: 1;
    justify-content: space-around;
  }
`
const StyledSearch = styled(Search)`
  margin-top: 18px;
  @media ${breakpoint.mobile} {
    order: 3;
  }
  max-width: 358px;
  div:first-of-type {
    border-radius: 2px;
  }
`

const StyledFilterButton = styled(FilterButton)`
  margin-top: 24px;
`

const StyledButton = styled(Button)`
  margin-top: 16px;
  border-radius: 4px;
  ::before {
    border-radius: 4px;
  }
  @media ${breakpoint.mobile} {
    order: 1;
  }
`

const StyledButton2 = styled(Button)`
  margin-top: 16px;
  width: 100%;
`

const StyledPlusIcon = styled(PlusIcon)`
  margin-right: 8px;
`

const StyledBreakFlex = styled('div')`
  @media ${breakpoint.mobile} {
    order: 2;
    flex-basis: 100%;
    width: 0px;
  }
`

const StyledModalContent = styled('div')``

const contentStyle: React.CSSProperties = {
  position: 'absolute',
  marginLeft: 'auto',
  marginRight: 'auto',
  left: '0',
  right: '0',
  marginTop: '212px',
  width: '98%',
  maxHeight: 'calc(100vh - 212px)',
  background: '#FFFFFF',

  overflow: 'scroll',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',

  border: '1px solid #E6EBF1',
  boxShadow: `none`,
  padding: '5px',
  boxSizing: 'border-box',
  boxShadow: '0px 8px 30px rgba(38, 44, 48, 0.22)',
}

const overlayStyle: React.CSSProperties = {
  background: 'rgba(0, 0, 0, 0)',
}

const StyledPopup = styled(Popup)`
  &-overlay {
    color: purple;
    background: rgba(0, 0, 0, 0);
  }

  &-content {
    position: absolute,
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbarwidth: none;
    max-height: 100vh;


    &::-webkit-scrollbar {
      display: none;
    }
  }
`

const StyledClearAll = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`
