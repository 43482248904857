import { action, observable } from 'mobx'

class AuthStore {
  @observable
  idToken: string | null

  @observable
  roleToken: string | null

  @observable
  accessToken: string | null

  @action
  setIdToken = (idToken: string): void => {
    this.idToken = idToken
  }

  @action
  getIdToken = (): string => {
    return this.idToken
  }

  @action
  setRoleToken = (roleToken: string): void => {
    this.roleToken = roleToken
  }

  @action
  setAccessToken = (accessToken: string): void => {
    this.accessToken = accessToken
  }

  @action
  getAccessToken = (): string => {
    return this.accessToken
  }

  @action
  reset = (): void => {
    this.idToken = null
    this.roleToken = null
    this.accessToken = null
  }

}

export const authStore = new AuthStore()
