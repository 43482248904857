import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import Popup from 'reactjs-popup'
import { StoreContext } from '../../store'

interface IProps {
  trigger: JSX.Element
  title?: string
  content?: string
  position?:
    | 'top left'
    | 'top center'
    | 'top right'
    | 'right top'
    | 'right center'
    | 'right bottom'
    | 'bottom left'
    | 'bottom center'
    | 'bottom right'
    | 'left top'
    | 'left center'
    | 'left bottom'
    | 'center center'
}

export const Tooltip: React.FC<IProps> = ({ trigger, title, content, children, position = 'left center' }) => {
  const { layoutStore } = useContext(StoreContext)
  const { isMobile } = layoutStore
  return (
    <>
      <Popup
        contentStyle={isMobile ? mobileContentStyle : contentStyle}
        arrowStyle={arrowStyle}
        trigger={trigger}
        position={'left center'}
        on="hover"
      >
        <div>
          {title && <StyledToolTipTitle>{title}</StyledToolTipTitle>}
          {content && <StyledToolTipContent>{content}</StyledToolTipContent>}
          {children && children}
        </div>
      </Popup>
    </>
  )
}

const mobileContentStyle: React.CSSProperties = {
  position: 'absolute',
  width: '208px',
  background: `#edbdbd`,
  border: `none`,
  boxShadow: `none`,
  padding: '5px',
  borderRadius: '4px',
}

const contentStyle: React.CSSProperties = {
  position: 'absolute',
  width: '325px',
  background: `#edbdbd`,
  border: `none`,
  boxShadow: `none`,
  padding: '5px',
  borderRadius: '4px',
}

const arrowStyle: React.CSSProperties = {
  height: '10px',
  width: '10px',
  position: 'absolute',
  background: '#edbdbd',
  transform: 'rotate(45deg)',
  margin: '-5px',
  boxShadow: 'none',
}

const StyledToolTipChildren = styled('div')`
  ${({ theme }) => theme.fontFamily.primary};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #1e3264;
`

const StyledToolTipTitle = styled.div(
  ({ theme }) => css`
    padding: 4px;
    font-family: ${theme.fontFamily.primary};
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: ${theme.color.brandNavy};
  `
)

const StyledToolTipContent = styled.div(
  ({ theme }) => css`
    padding: 4px;
    font-family: ${theme.fontFamily.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${theme.color.brandNavy};
  `
)
