import React from 'react'
import { components } from 'react-select'
import { observer } from 'mobx-react-lite'
import Theme from '@postidigital/posti-theme'
import { IDropDownOption, IGroupedDropDownOptions } from '../../store/dataModels/interfaces'
import { DropdownMenuItem } from '@postidigital/posti-components'
import AllSelect from '../../components/general/dropdown/AllSelect'
import styled from 'styled-components'

const { ValueContainer, Placeholder } = components

// How to style https://react-select.com/styles
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    // backgroundColor: Theme.color.neutralGray2,
    backgroundColor: '#FFFFFF',
    borderRadius: '20px',
    minHeight: '32px',
    // '&:hover': { borderColor: `${Theme.color.signalBlue}` },
    border: state.isDisabled ? '1px solid #C2C9D1' : '1px solid #73808C',
    color: state.isDisabled ? '#C2C9D1' : '#3B4A57',
    // border: state.isFocused ? `3px solid ${Theme.color.signalBlue}` : `3px solid ${Theme.color.neutralGray2}`,
    // borderBottom: state.isFocused
    //   ? `3px solid ${Theme.color.signalBlue}`
    //   : `3px solid ${Theme.color.neutralPassiveGray}`,
    marginTop: '16px',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontFamily: Theme.fontFamily.primary,
    color: Theme.color.neutralNetworkGray,
  }),

  menu: (provided, state) => ({
    ...provided,
    fontFamily: Theme.fontFamily.primary,
    color: Theme.color.neutralNetworkGray,
    zIndex: 9999,
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    display: 'none',
    fontFamily: Theme.fontFamily.primary,
    color: Theme.color.neutralNetworkGray,
    fontSize: `${Theme.fontSize.text.md}`,
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    display: 'none',
    fontFamily: Theme.fontFamily.primary,
    color: Theme.color.neutralNetworkGray,
    fontSize: `${Theme.fontSize.text.md}`,
    '&:hover': {
      backgroundColor: `inherit`,
      color: `inherit`,
      cursor: 'pointer',
    },
  }),
  indicatorSeparator: styles => ({ display: 'none' }),
  singleValue: (provided, state) => {
    const opacity = 0.5

    return { ...provided, opacity }
  },
  placeholder: (base, state) => ({
    display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block',
  }),
}

// If you are using mobx, be sure to covert your options to JS
// before passing as props to this component
// const optionsToJs = toJS(options)
// const selectedOptionsToJs = toJS(selectedOptions)
interface IProps {
  id?: string
  options: IDropDownOption[] | IGroupedDropDownOptions[]
  selectedOptions: IDropDownOption[]
  isMulti?: boolean
  placeHolderText?: string
  onChange?: (values: IDropDownOption[]) => any
  allowSelectAll?: boolean
  allOption: IDropDownOption // required, will brake if not passed
  // This disbles all options, you can still view them
  // do not pass a onChange function
  disabled?: boolean
  // if you want to disable a single option. You have to
  // pass the option with a disabled prop EG:
  // const options = [
  // [{label: "one", value: 1, disabled: true},
  // {label: "two", value: 2}]
  grouped?: boolean
  // the users has passed options in a group structure
}

// styles for disabled

const FilterDropdownComponent: React.FC<IProps> = ({
  id,
  options,
  onChange,
  selectedOptions,
  isMulti = false,

  placeHolderText = '',
  allowSelectAll = false,
  allOption,
  disabled,
  grouped,
}) => {
  const handleChange = selectedOption => {
    if (!selectedOption) {
      return onChange([])
    }

    if (Array.isArray(selectedOption)) {
      return onChange(selectedOption)
    }

    const values = [selectedOption]
    return onChange(values)
  }

  const menuPortalTarget = document.getElementById(document.body)

  return (
    <AllSelect
      id={id}
      grouped={grouped}
      isDisabled={disabled}
      value={selectedOptions}
      onChange={handleChange}
      isMulti={isMulti}
      options={options}
      styles={customStyles}
      placeholder={placeHolderText}
      components={{
        MultiValue,
        ValueContainer: CustomValueContainer,
        Option: renderOption,
      }}
      noOptionsMessage={() => null}
      hideSelectedOptions={false}
      menuPlacement="auto"
      menuPortalTarget={menuPortalTarget}
      allowSelectAll={allowSelectAll}
      allOption={allOption}
      closeMenuOnSelect={false}
    />
  )
}

const renderOption = ({ value, label, isSelected, innerRef, innerProps, isDisabled, ...props }) => {
  return (
    <StyledDropdownMenuItem ref={innerRef} {...innerProps} id={value} selected={isSelected} disabled={isDisabled}>
      {label}
    </StyledDropdownMenuItem>
  )
}

const MultiValue = props => {
  const allOption = props.options[0]

  let labelToBeDisplayed = `${props.data.label}`
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = allOption.label
  }

  return <components.MultiValue {...props}>{labelToBeDisplayed}</components.MultiValue>
}

const CustomValueContainer = ({ children, ...props }) => {
  let optionAmount = props.selectProps.options.length
  if (props.selectProps.grouped) {
    const flatOptions = props.selectProps.options.reduce((accumulatedOptions, currentGroup) => {
      return accumulatedOptions.concat(currentGroup.options)
    }, [])
    optionAmount = flatOptions.length
  }

  let selectedAmount = props.selectProps.value.length
  if (optionAmount === selectedAmount) {
    selectedAmount = optionAmount - 1
  }

  return (
    <ValueContainer {...props}>
      {props.isFocused ? null : (
        <Placeholder {...props} isFocused={props.isFocused}>
          {selectedAmount ? `${props.selectProps.placeholder} (${selectedAmount})` : props.selectProps.placeholder}
        </Placeholder>
      )}
      {React.Children.map(children, child => (child && child.type !== Placeholder ? child : null))}
    </ValueContainer>
  )
}

export const FilterDropdown = observer(FilterDropdownComponent)

const StyledDropdownMenuItem = styled(DropdownMenuItem)`
  span {
    display: inline-block;
    width: 100%;
    text-overflow: ellipsis;
  }
`
