import { observable, action, runInAction, computed, toJS } from 'mobx'
import { serviceAdapter } from '../service/serviceAdapter'
import {
  IOrgUser,
  IInvite,
  IDropDownOption,
  IOrganizationItem,
  IGroupedDropDownOptions,
  DropDownType,
  INumberOption,
} from './dataModels/interfaces'
import { sortIDropDownOptionsByDisabledStatusAndValue } from '../utils/helpers'
import { debounce } from 'lodash'
import I18n from '../utils/i18n'

export class SearchStore {
  @observable
  isLoading: boolean = true

  @observable
  isFetchingCsv: boolean = false

  @observable
  users: IOrgUser[] = []

  @observable
  invites: IInvite[] = []

  @observable
  firstLoadDone = false

  @observable
  searchTerm: string = ''

  @observable
  searchMainUsers: boolean = false

  @observable
  organizations: IOrganizationItem[] = []

  // list of organisations the user can filter by
  @observable
  organisationsOptions: IDropDownOption[] | null = []

  @observable
  selectedOrganisations: IDropDownOption[] | null = []

  @computed
  get customerNumbers(): IGroupedDropDownOptions[] {
    const selectedBusinessIds: string[] = this.selectedOrganisations.map(org => org.value)

    const cnNumbers = []

    this.organizations.map(org => {
      if (selectedBusinessIds.includes(org.businessId)) {
        cnNumbers.push({ label: org.name, options: toJS(org.customerNumbers) })
      }
    })

    return cnNumbers
  }

  @computed
  get LSCN(): IGroupedDropDownOptions[] {
    const selectedBusinessIds: string[] = this.selectedOrganisations.map(org => org.value)

    const lscnNumbers = []

    this.organizations.map(org => {
      if (selectedBusinessIds.includes(org.businessId)) {
        lscnNumbers.push({ label: org.name, options: toJS(org.logisticsContractNumbers) })
      }
    })

    return lscnNumbers
  }

  @computed
  get transportIds(): IGroupedDropDownOptions[] {
    const selectedBusinessIds: string[] = this.selectedOrganisations.map(org => org.value)

    const transportIds = []

    this.organizations.map(org => {
      if (selectedBusinessIds.includes(org.businessId)) {
        transportIds.push({ label: org.name, options: toJS(org.transportIds) })
      }
    })

    return transportIds
  }

  @computed
  get businessPartnerNumbers(): IGroupedDropDownOptions[] {
    const selectedBusinessIds: string[] = this.selectedOrganisations.map(org => org.value)

    const businessPartnerNumbers: IGroupedDropDownOptions[] = []

    this.organizations.map(org => {
      if (selectedBusinessIds.includes(org.businessId)) {
        businessPartnerNumbers.push({ label: org.name, options: toJS(org.businessPartnerNumbers) })
      }
    })

    return businessPartnerNumbers
  }

  @observable
  selectedCustomerNumbers: IDropDownOption[] | null = []

  @observable
  selectedLSCN: IDropDownOption[] | null = []

  @observable
  selectedTransportIds: IDropDownOption[] | null = []

  @observable
  selectedBusinessPartnerNumbers: IDropDownOption[] | null = []

  @observable
  selectedRoles: string[] | null = []

  @observable
  isSearching: boolean = false

  @action
  toggleSelectedRole = role => {
    if (this.selectedRoles.includes(role)) {
      this.selectedRoles = this.selectedRoles.filter(r => r !== role)
    } else {
      this.selectedRoles.push(role)
    }
    this.debouncedFetchResults()
  }

  @action
  async fetchUsersAndInvites() {
    this.isLoading = true
    try {
      let response = await serviceAdapter.sendGetRequest('/api/landingpage')

      if (response.status >= 400) {
        throw new Error('Bad response from server')
      }
      const data = await response.json()

      runInAction(() => {
        this.users = data.users
        this.invites = data.invites
        this.isLoading = false
        this.firstLoadDone = true
      })
    } catch (err) {
      console.error(err)
    }
  }

  @action
  async fetchOrganisations() {
    this.isLoading = true
    try {
      let response = await serviceAdapter.sendGetRequest('/api/user/organizations')

      if (response.status >= 400) {
        throw new Error('Bad response from server')
      }
      const data = await response.json()

      runInAction(() => {
        // In the dropdowns can not handle duplicate Options with the same "value"
        // that is why the bussinessID is appended here to every option value
        // this is done here, but could be done serverside, if there are performance issues
        this.organizations = data.map((org, idx) => {
          const toDropDownOption = (item: INumberOption, type: DropDownType): IDropDownOption => {
            return {
              label: `${item.value} ${item.label}`,
              value: `${org.businessId}___${item.value}`,
              type: type,
              isDisabled: typeof item.disabled !== 'undefined' ? item.disabled : false,
            }
          }
          org.customerNumbers = org.customerNumbers
            .map(
              (item: INumberOption): IDropDownOption => {
                return toDropDownOption(item, DropDownType.CUSTOMER_NUMBER)
              }
            )
            .sort(sortIDropDownOptionsByDisabledStatusAndValue)
          org.logisticsContractNumbers = org.logisticsContractNumbers
            .map(
              (item: INumberOption): IDropDownOption => {
                return toDropDownOption(item, DropDownType.LOGISTICS_CONTRACT_NUMBER)
              }
            )
            .sort(sortIDropDownOptionsByDisabledStatusAndValue)
          org.transportIds = org.transportIds
            .map(
              (item: INumberOption): IDropDownOption => {
                return toDropDownOption(item, DropDownType.TRANSPORT_ID)
              }
            )
            .sort(sortIDropDownOptionsByDisabledStatusAndValue)
          org.businessPartnerNumbers = org.businessPartnerNumbers
            .map(
              (item: INumberOption): IDropDownOption => {
                return toDropDownOption(item, DropDownType.BUSINESS_PARTNER_NUMBER)
              }
            )
            .sort(sortIDropDownOptionsByDisabledStatusAndValue)

          return org
        })
        this.organisationsOptions = data.map(org => {
          return { label: org.name, value: org.businessId, type: DropDownType.ORGANIZATION } as IDropDownOption
        })
      })
    } catch (err) {
      console.error(err)
    }
  }

  @action
  async updateSingleInvite(newInvite: IInvite, replaceInviteId: string) {
    const newInvites = this.invites.map(invite => {
      if (invite.inviteId === replaceInviteId) {
        return newInvite
      }
      return invite
    })
    this.invites = newInvites
  }

  @action
  toggleSearchMainUsers = () => {
    this.searchMainUsers = !this.searchMainUsers
    this.debouncedFetchResults()
  }

  @action
  setSelectedOrganisations = numbers => {
    this.clearFilters({ noFetch: true, clearUserType: false })
    this.selectedOrganisations = numbers
    this.debouncedFetchResults()
  }

  @action
  setSelectedCustomerNumbers = (numbers: IDropDownOption[]) => {
    this.selectedCustomerNumbers = numbers
    this.debouncedFetchResults()
  }

  @action
  setSelectedLSCN = (numbers: IDropDownOption[]) => {
    this.selectedLSCN = numbers
    this.debouncedFetchResults()
  }

  @action
  setSelectedTransportIds = (numbers: IDropDownOption[]) => {
    this.selectedTransportIds = numbers
    this.debouncedFetchResults()
  }

  @action
  setSelectedBusinessPartnerNumbers = (numbers: IDropDownOption[]) => {
    this.selectedBusinessPartnerNumbers = numbers
    this.debouncedFetchResults()
  }

  @action
  clearFilters = (options?: {
    noFetch?: boolean
    clearUserType?: boolean
    clearOrganizations?: boolean
    clearCustomerNumbers?: boolean
    clearLogisticsContracts?: boolean
    clearTransportIds?: boolean
    clearBusinessPartnerNumbers?: boolean
    clearRoles?: boolean
  }) => {
    const defaults = {
      noFetch: false,
      clearUserType: true,
      clearOrganizations: true,
      clearCustomerNumbers: true,
      clearLogisticsContracts: true,
      clearTransportIds: true,
      clearBusinessPartnerNumbers: true,
      clearRoles: true,
    }

    const optionValue = {
      ...defaults,
      ...options,
    }

    if (optionValue.clearOrganizations) {
      this.selectedOrganisations = []
    }
    if (optionValue.clearCustomerNumbers) {
      this.selectedCustomerNumbers = []
    }
    if (optionValue.clearLogisticsContracts) {
      this.selectedLSCN = []
    }
    if (optionValue.clearTransportIds) {
      this.selectedTransportIds = []
    }
    if (optionValue.clearBusinessPartnerNumbers) {
      this.selectedBusinessPartnerNumbers = []
    }
    if (optionValue.clearRoles) {
      this.selectedRoles = []
    }
    if (optionValue.clearUserType) {
      this.searchMainUsers = false
    }

    if (!optionValue.noFetch) {
      this.debouncedFetchResults()
    }
  }

  @action
  setSearchTerm = (searchTerm: string) => {
    if (searchTerm != this.searchTerm) {
      this.searchTerm = searchTerm
      this.debouncedFetchResults()
    }
  }

  // this function gets the numbers, and removes the extra part of the object.value that is added so it will work with
  // the dropdowns (EG xxxxx____valueID), the xxxx____ part is removed
  @action
  getStringsFromSelectedNumbers = (numbers: IDropDownOption[]): string[] => {
    const toJSNumbers = toJS(numbers)
    const filtered = toJSNumbers.filter(number => number.value !== '*')
    return filtered.map(number => removeExtrea(number.value))
  }

  @action
  fetchResuls = async () => {
    runInAction(() => {
      this.isSearching = true
    })

    try {
      const searchObject = {
        searchTerm: this.searchTerm,
        customerNumbers: this.getStringsFromSelectedNumbers(this.selectedCustomerNumbers),
        logisticContractNumbers: this.getStringsFromSelectedNumbers(this.selectedLSCN),
        transportIds: this.getStringsFromSelectedNumbers(this.selectedTransportIds),
        businessPartnerNumbers: this.getStringsFromSelectedNumbers(this.selectedBusinessPartnerNumbers),
        organizations: this.selectedOrganisations.filter(number => number.value !== '*').map(number => number.value),
        mainUser: this.searchMainUsers,
        roles: this.selectedRoles,
      }

      let response = await serviceAdapter.sendPostRequest('/api/search', searchObject)

      if (response.status >= 400) {
        throw new Error('Bad response from server')
      }
      const data = await response.json()
      const { users, invites } = data

      runInAction(() => {
        this.users = users
        this.invites = invites
        this.isSearching = false
      })
    } catch (err) {
      console.error(err)
    }
  }

  @action
  fetchCsv = async () => {
    runInAction(() => {
      this.isFetchingCsv = true
    })

    try {
      const searchObject = {
        searchTerm: this.searchTerm,
        customerNumbers: this.getStringsFromSelectedNumbers(this.selectedCustomerNumbers),
        logisticContractNumbers: this.getStringsFromSelectedNumbers(this.selectedLSCN),
        transportIds: this.getStringsFromSelectedNumbers(this.selectedTransportIds),
        businessPartnerNumbers: this.getStringsFromSelectedNumbers(this.selectedBusinessPartnerNumbers),
        organizations: this.selectedOrganisations.filter(number => number.value !== '*').map(number => number.value),
        mainUser: this.searchMainUsers,
        roles: this.selectedRoles,
        locale: I18n.getLocale,
      }

      let response = await serviceAdapter.sendPostRequest('/api/search/csv', searchObject)
      if (response.status >= 400) {
        runInAction(() => {
          this.isFetchingCsv = false
        })
        throw new Error('Bad response from server')
      }

      // SIMPLE SOLUTION
      const data = await response.blob()
      // Create ObjectURL and download file
      const url = window.URL.createObjectURL(data)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = 'search_users.csv'
      document.body.appendChild(a)
      a.click()
      // Clean up
      window.URL.revokeObjectURL(url)
      a.remove()
      runInAction(() => {
        this.isFetchingCsv = false
      })
    } catch (err) {
      console.error(err)
    }
  }

  @action
  debouncedFetchResults = debounce(this.fetchResuls, 1000)

  @action
  removeDeletedUser = (email: string) => {
    const filteredUsers = toJS(this.users).filter(user => {
      return user.email !== email
    })
    this.users = filteredUsers
  }
}

export const searchStore = new SearchStore()

const removeExtrea = (value): string => {
  return value.split('___')[1]
}
