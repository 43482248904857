import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { sendPageInfo, sendUserAction } from '@postidigital/posti-google-analytics'
import { StoreContext } from '../../store'
import { FetchingAnimation, FetchingTitle } from '../../components'
import i18n from '../../utils/i18n'
import { useHistory, useParams } from 'react-router'
import { CSATSelector } from '../general/CSATSelector'
import { DoneButton } from '../buttons/buttons'

interface IProp {
  organisationData: IUserDetailOrganisation
}

type Props = IProp & Partial<TransProps>

const EditStep3Component: React.FC<Props> = ({ organizationData }) => {
  const { userDetailsStore } = useContext(StoreContext)
  const { pendingRequest } = userDetailsStore
  const { orgid, userid } = useParams()
  const { organizationUser } = organizationData
  const { selectedRoles } = organizationUser
  const history = useHistory()

  useEffect(() => {
    sendPageInfo({
      language: i18n.getLocale,
      solution: 'oneaccount-orgadmin',
      domain: window.location.hostname,
      pagePath: `/org-admin/edit-user/${userid}/${orgid}/3`,
      pageName: 'oneaccount-orgadmin-edit-users',
    })
  }, [])

  useEffect(() => {
    if (pendingRequest === 'success' || pendingRequest === 'error') {
      sendUserAction({
        actionType: pendingRequest === 'success' ? 'editUserSuccess' : 'editUserError',
        businessId: orgid,
        userType: selectedRoles.includes('MainUser') ? 'main user' : 'user',
      })
    }
  }, [pendingRequest])

  return (
    <>
      <FetchingTitle pendingRequest={pendingRequest} type="editUser" />
      <StyledCentered>
        <FetchingAnimation pendingRequest={pendingRequest} />
      </StyledCentered>
      {pendingRequest === 'success' && <CSATSelector />}
      <StyledButtonContainer>
        <DoneButton
          onClick={() => {
            history.goBack()
          }}
        />
      </StyledButtonContainer>
    </>
  )
}

export const EditStep3 = observer(EditStep3Component)

const StyledCentered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 24px;
  width: fit-content;
`
const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  margin-bottom: 24px;
`
