import { observable, action, toJS, runInAction } from 'mobx'
import { serviceAdapter } from '../service/serviceAdapter'
import { IUserDetailOrganisation, IDropDownOption, IInvite, DropDownType } from './dataModels/interfaces'
import { arrayIsEmpty, addValuesToLabels } from '../utils/helpers'
import { searchStore } from '../store'
import { OutboundMessageType, sendMessage } from '../utils'

export class UserDetailsStore {
  @observable
  userId: string = ''

  @observable
  step: number = 1

  @observable
  firstName: string

  @observable
  lastName: string

  @observable
  email: string

  @observable
  phone: string

  @observable
  invites: IInvite[]

  @observable
  isLoading: boolean = true

  @observable
  userOrganizations: IUserDetailOrganisation[]

  @observable
  changesMade: boolean = false

  @observable
  pendingRequest: string = ''

  //  Numbers to be displayed in step 2
  @observable
  customerNumbers: IDropDownOption[] | null = null

  @observable
  LSCNumbers: IDropDownOption[] | null = null

  @observable
  transportIds: IDropDownOption[] | null = null

  @observable
  businessPartnerNumbers: IDropDownOption[] | null = null

  @action
  setStep = (step: number) => {
    this.step = step
  }

  @action
  async fetchUserData(organizationUserId: string): Promise<void> {
    try {
      runInAction(() => {
        this.isLoading = true
      })

      const response = await serviceAdapter.sendGetRequest(`/api/userdetails/${organizationUserId}`)

      if (response.status >= 400) {
        throw new Error('Bad response from server')
      }
      const data = await response.json()

      runInAction(() => {
        this.userId = organizationUserId
        this.firstName = data.firstName
        this.lastName = data.lastName
        this.email = data.email
        this.phone = data.phone
        this.userOrganizations = data.organizations
        this.invites = data.invites

        this.isLoading = false
      })
    } catch (err) {
      console.error(err)
    }
  }

  @action
  toggleUserRole = (orgId: string, userRole: string) => {
    this.setChangesMade(true)

    const organisationData = this.userOrganizations.find(org => org.organization.businessId === orgId)

    const { organizationUser } = organisationData
    let { selectedRoles } = organizationUser

    if (!selectedRoles.includes(userRole)) {
      return selectedRoles.push(userRole)
    }

    const newRoles = selectedRoles.filter(role => role !== userRole)
    const idx = this.userOrganizations.findIndex(org => org.organization.businessId === orgId)
    this.userOrganizations[idx].organizationUser.selectedRoles = newRoles
  }

  @action
  setChangesMade = (made: boolean) => {
    this.changesMade = made
  }

  @action
  getCustomerNumbersAndServiceContracts = async (orgId: string, selectedRoles: string[]) => {
    try {
      runInAction(() => {
        this.pendingRequest = 'loading'
      })

      const postObject = {
        roles: toJS(selectedRoles),
      }

      const response = await serviceAdapter.sendPostRequest(`/api/contract-numbers/${orgId}/available`, postObject)

      if (response.status >= 400) {
        runInAction(() => {
          this.pendingRequest = 'error'
        })

        throw new Error('Bad response from server')
      }

      const data = await response.json()

      runInAction(() => {
        this.customerNumbers = arrayIsEmpty(data.customerNumbers)
          ? []
          : addValuesToLabels(data.customerNumbers, DropDownType.CUSTOMER_NUMBER)
        this.LSCNumbers = arrayIsEmpty(data.logisticsContractNumbers)
          ? []
          : addValuesToLabels(data.logisticsContractNumbers, DropDownType.LOGISTICS_CONTRACT_NUMBER)
        this.transportIds = arrayIsEmpty(data.transportIds)
          ? []
          : addValuesToLabels(data.transportIds, DropDownType.TRANSPORT_ID)
        this.businessPartnerNumbers = arrayIsEmpty(data.businessPartnerNumbers)
          ? []
          : addValuesToLabels(data.businessPartnerNumbers, DropDownType.BUSINESS_PARTNER_NUMBER)
        this.pendingRequest = ''
      })
    } catch (err) {
      console.error(err)
      runInAction(() => {
        this.pendingRequest = 'error'
      })
    }
  }

  @action
  setSelectedCustomerNumbers = (orgId: string, selectedCustomerNumbers: string[]) => {
    const idx = this.userOrganizations.findIndex(org => org.organization.businessId === orgId)

    this.userOrganizations[idx].organizationUser.selectedCustomerNumbers = selectedCustomerNumbers
    this.setChangesMade(true)
  }

  @action
  setSelectedLSCNumbers = (orgId: string, selectedLogisticsContractNumbers: string[]) => {
    const idx = this.userOrganizations.findIndex(org => org.organization.businessId === orgId)

    this.userOrganizations[idx].organizationUser.selectedLogisticsContractNumbers = selectedLogisticsContractNumbers
    this.setChangesMade(true)
  }

  @action
  setSelectedTransportIds = (orgId: string, selectedTransportIds: string[]) => {
    const idx = this.userOrganizations.findIndex(org => org.organization.businessId === orgId)

    this.userOrganizations[idx].organizationUser.selectedTransportIds = selectedTransportIds
    this.setChangesMade(true)
  }

  @action
  setSelectedBusinessPartnerNumbers = (orgId: string, selectedBusinessPartnerNumbers: string[]) => {
    const idx = this.userOrganizations.findIndex(org => org.organization.businessId === orgId)

    this.userOrganizations[idx].organizationUser.selectedBusinessPartnerNumbers = selectedBusinessPartnerNumbers
    this.setChangesMade(true)
  }

  @action
  postChangesToBackend = async (orgId: string, shouldRefreshToken: boolean = false) => {
    try {
      runInAction(() => {
        this.pendingRequest = 'loading'
      })

      const organisationToBeEdited = this.userOrganizations.find(org => org.organization.businessId === orgId)

      const postObject = {
        roleValues: toJS(organisationToBeEdited.organizationUser.selectedRoles).filter(string => string !== '*'),
        customerNumbers: handleNumbers(
          Boolean(this.customerNumbers),
          organisationToBeEdited.organizationUser.selectedCustomerNumbers
        ),
        logisticsContractNumbers: handleNumbers(
          Boolean(this.LSCNumbers),
          organisationToBeEdited.organizationUser.selectedLogisticsContractNumbers
        ),
        transportIds: handleNumbers(
          Boolean(this.transportIds),
          organisationToBeEdited.organizationUser.selectedTransportIds
        ),
        businessPartnerNumbers: handleNumbers(
          Boolean(this.businessPartnerNumbers),
          organisationToBeEdited.organizationUser.selectedBusinessPartnerNumbers
        ),
      }

      const response = await serviceAdapter.sendPostRequest(
        `/api/userdetails/${organisationToBeEdited.organizationUser.organizationUserId}`,
        postObject
      )

      if (response.status >= 400) {
        runInAction(() => {
          this.pendingRequest = 'error'
        })

        throw new Error('Bad response from server')
      }
      runInAction(() => {
        this.pendingRequest = 'success'
        if (shouldRefreshToken) {
          sendMessage({ type: OutboundMessageType.REFRESH, payload: null })
        }

        //SET NUMBERS IN FRONTENT?
      })
    } catch (err) {
      console.error(err)
      runInAction(() => {
        this.pendingRequest = 'error'
      })
    }
  }

  @action
  setPendingRequest = (pendingRequest: string) => {
    this.pendingRequest = pendingRequest
  }

  @action
  removeOrganisation = async (businessId: string, accountId: string, email: string) => {
    try {
      runInAction(() => {
        this.pendingRequest = 'loading'
      })

      const response = await serviceAdapter.sendDeleteRequest(`/api/organization-users/${businessId}/${accountId}`)

      if (response.status >= 400) {
        runInAction(() => {
          this.pendingRequest = 'error'
          // Todo show error in userDetailsViews if error
        })

        throw new Error('Bad response from server')
      }

      runInAction(() => {
        this.pendingRequest = ''
        const filteredOrgs = toJS(this.userOrganizations).filter(org => {
          return org.organization.businessId !== businessId
        })
        this.userOrganizations = filteredOrgs

        if (filteredOrgs.length === 0) {
          searchStore.removeDeletedUser(email)
        }
      })
    } catch (err) {
      console.error(err)
      runInAction(() => {
        this.pendingRequest = 'error'
      })
    }
  }
}

export const userDetailsStore = new UserDetailsStore()

const handleNumbers = (userNeedsNumbers: boolean, numbers: string[]): string[] => {
  if (!userNeedsNumbers) {
    return []
  }
  return toJS(numbers).filter(string => string !== '*')
}
