import React from 'react'
import { observer } from 'mobx-react-lite'
import { RadioGroup, Radio } from '@postidigital/posti-components'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface IProps {
  isMainUser: boolean
  disabled?: boolean
  toggleUserRole?: () => any
  horizontal?: boolean
  plurarTranslations?: boolean
  small?: boolean
}

const options = {
  en: [
    { id: 'mainuser_radio_mainuser', label: 'Main User', value: 'MainUser' },
    { id: 'mainuser_radio_user', label: 'User', value: 'User' },
  ],
  fi: [
    { id: 'mainuser_radio_mainuser', label: 'Pääkäyttäjä', value: 'MainUser' },
    { id: 'mainuser_radio_user', label: 'Käyttäjä', value: 'User' },
  ],
  sv: [
    { id: 'mainuser_radio_mainuser', label: 'Huvudanvändare', value: 'MainUser' },
    { id: 'mainuser_radio_user', label: 'Användare', value: 'User' },
  ],
}

const optionsPlurar = {
  en: [
    { id: 'mainuser_radio_user', label: 'All Users', value: 'User' },
    { id: 'mainuser_radio_mainuser', label: 'Main Users', value: 'MainUser' },
  ],
  fi: [
    { id: 'mainuser_radio_user', label: 'Kaikki Käyttäjät', value: 'User' },
    { id: 'mainuser_radio_mainuser', label: 'Pääkäyttäjät', value: 'MainUser' },
  ],
  sv: [
    { id: 'mainuser_radio_user', label: 'Alla Användare', value: 'User' },
    { id: 'mainuser_radio_mainuser', label: 'Huvudanvändare', value: 'MainUser' },
  ],
}

const MainUserRadioGroupComponent: React.FC<IProps> = ({
  isMainUser,
  toggleUserRole,
  disabled,
  horizontal,
  plurarTranslations,
  small,
}) => {
  const { i18n } = useTranslation()
  const curLang = i18n.language

  let selectedUserRole
  if (!plurarTranslations) {
    selectedUserRole = isMainUser ? options[curLang][0] : options[curLang][1]
  } else {
    selectedUserRole = !isMainUser ? optionsPlurar[curLang][0] : optionsPlurar[curLang][1]
  }

  const opt = plurarTranslations ? optionsPlurar : options

  return (
    <StyledContainer>
      <StyledRadioGroup
        alignedHorizontally={horizontal}
        labelPosition="right"
        name="MainUserRadioGroupComponent"
        onChange={() => toggleUserRole()}
        value={selectedUserRole.value}
      >
        {opt[curLang].map(opt => (
          <StyledRadio
            key={opt.id}
            disabled={disabled}
            label={opt.label}
            value={opt.value}
            labelPosition="right"
            name="radio-group-horizontal"
            size={small ? 'small' : 'default'}
            checked={opt.label === selectedUserRole.label}
          />
        ))}
      </StyledRadioGroup>
    </StyledContainer>
  )
}

export const MainUserRadioGroup = observer(MainUserRadioGroupComponent)

const StyledUserSelection = styled('div')`
  display: flex;
  flex-direction: row;
`

const StyledContainer = styled('div')`
  margin: 0 8px;
`

const StyledRadioGroup = styled(RadioGroup)`
  ${props =>
    props.alignedHorizontally
      ? ``
      : `
      > div {
        &:first-child {
          padding-bottom: 0;
        }
        &:last-child {
          padding-top: 2rem;
        }
      }
      `}
`

const StyledRadio = styled(Radio)`
  padding: 1.5rem 0;
  padding-right: 16px;
  font-size: 14px;
  line-height: 21px;
`
