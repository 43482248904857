import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from '../../../utils/breakpoint'

export interface IProps {
  children: JSX.Element[]
}

export const Tabs: React.FC<IProps> = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState(0)

  const renderLables = () => {
    return children.map((child, i) => {
      return (
        <StyledLabel
          id={`${i}`}
          selected={i === selectedTab}
          key={child.props.label}
          onClick={() => {
            setSelectedTab(i)
          }}
        >
          {child.props.label}
        </StyledLabel>
      )
    })
  }

  return (
    <StyledTabsWrapper>
      <StyledLabelWrapper>{renderLables()}</StyledLabelWrapper>
      <StyledContentWrapper>{children[selectedTab]}</StyledContentWrapper>
    </StyledTabsWrapper>
  )
}

const StyledLabel = styled.div<{
  selected: boolean
}>(
  ({ theme, selected }) => css`
    font-family: ${theme.fontFamily.primary};
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    font-size: ${theme.fontSize.title.xs};
    color:${theme.color.neutralPassiveGray};
    
    padding: 8px;
    margin-left: 32px;
    margin-right 32px;
    
    ${selected &&
      css`
        color: ${theme.color.neutralBlack};
        border-bottom: 3px solid ${theme.color.signalBlue};
      `}
    
  `
)

const StyledLabelWrapper = styled.div(
  ({ theme }) => css`
    width: 100%;

    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    border-bottom: 1px solid ${theme.color.neutralPassiveGray};
    @media ${breakpoint.mobile} {
      border-bottom: none;
    }
  `
)

const StyledContentWrapper = styled.div`
  width: 100%;
  height: 100%;
`
const StyledTabsWrapper = styled.div`
  margin-top: 32px;
`
