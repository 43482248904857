import React, { useContext } from 'react'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next'
import { Headline } from '@postidigital/posti-components'
import { StoreContext } from '../../store'
import { RouteComponentProps, withRouter } from 'react-router'
import { observer } from 'mobx-react-lite'
import { InviteList, ViewLoading } from '../../components'

type Props = RouteComponentProps

export const InviteDetailsComponent: React.FC<Props> = () => {
  const { organisationStore } = useContext(StoreContext)
  const { invites, isLoading } = organisationStore

  const { t } = useTranslation()

  if (isLoading) {
    return <ViewLoading />
  }

  return (
    <div>
      <StyledHeadline as="div" size="Four" color="#000000">
        {` ${t(`landing.invitations`)} (${invites.length})`}
      </StyledHeadline>
      <InviteList invites={invites} />
    </div>
  )
}

export const InviteDetails = withRouter(observer(InviteDetailsComponent))

const StyledHeadline = styled(Headline)`
  margin-top: 32px;
  margin-bottom: 16px;
`
