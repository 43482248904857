import React, { useContext, useEffect, useRef, useState } from 'react'
import { Switch, Route } from 'react-router'
import { BrowserRouter, useHistory } from 'react-router-dom'
import styled, { createGlobalStyle, ThemeProvider, css } from 'styled-components'
import Theme, { XyzTheme } from '@postidigital/posti-theme'
import { GlobalStyleNormalization } from '@postidigital/posti-components'
import { I18nextProvider } from 'react-i18next'
import { InboundMessage, InboundMessageType, OutboundMessageType, i18nInstance, sendMessage } from '../utils'
import { useTranslation } from 'react-i18next'
import { findDOMNode, unmountComponentAtNode } from 'react-dom'

import { ExtendedTheme } from '../utils'
import { MainView } from './mainView'
import { InviteView } from './inviteView'
import { OrganisationsView } from './organisationsView'
import { SingleOrgView } from './singleOrgView'
import { UserDetailView } from './userDetailView'
import { InviteDetailsView } from './inviteDetailsView'
import { EditUserView } from './editUserView'
import { ErrorBoundary, ErrorComponent, InviteStep2, InviteStep3, InviteStep4, InviteFailure, ViewLoading } from '../components'
import { StoreContext } from '../store'
import { observer } from 'mobx-react-lite'
import { initLocales } from '../locales'
import { ID } from '../constants'
import { getBasePath, getCurrentBasePath } from '../utils/helpers'
import { breakpoint } from '../utils/breakpoint'
import { serviceAdapter } from '../service/serviceAdapter'
import i18n from '../utils/i18n'

initLocales()
serviceAdapter.setEndpointURL(window.location.hostname)

// Set custom global styles here. Most of these should come from GlobalStyleNormalization
const GlobalStyle = createGlobalStyle`
  * {
    -webkit-tap-highlight-color: transparent;
  }
`

const extendedTheme: ExtendedTheme = { ...Theme, color: { ...Theme.color, paleRed: '#fdf5f8' }, xyz: { ...XyzTheme } }

interface IProps {
  path: string
  component: React.ComponentClass
  exact?: boolean
  computedMatch?: { params: { lang: string } }
}

const NavRoute: React.FC<IProps> = ({ exact, path, component: Component }) => {
  // This is a hacky solution for navigating the users to the correct location, when using the
  // sidebar from oma-posti.
  // this code polls every 500 ms, checks the current url
  // checks if the user has navigated to either tha mainView or the OrganisationsView
  // through the left sidebar and the navigates to that path
  const history = useHistory()
  const { i18n } = useTranslation()
  const basePath = getCurrentBasePath(i18n)
  const origin = window.location.origin

  useEffect(() => {
    // store url on load
    let currentPage = location.href

    // listen for changes
    const pollLocation = setInterval(function() {
      if (currentPage != location.href) {
        currentPage = location.href

        // check if the browser has moved to either '/' or '/organisations'
        if (location.href === `${origin}${basePath}` || location.href === `${origin}${basePath}/organisations`) {
          if (location.href === `${origin}${basePath}`) {
            history.push(`${basePath}/`)
          } else {
            history.push(`${basePath}/organisations`)
          }
        }
      }
    }, 500)

    return () => {
      clearTimeout(pollLocation)
    }
  }, [])

  // if mainView
  if ([`${origin}${basePath}`, `${origin}${basePath}/`].includes(location.href)) {
    return (
      <Route
        exact={exact}
        path={path}
        render={props => (
          <StyledContainerMainView>
            <Component {...props} />
          </StyledContainerMainView>
        )}
      />
    )
  }
  const pathsThatNeedPadding = ['/user/', '/invite-detail/', '/organisations', '/organisation']
  const addMobileTopPadding = pathsThatNeedPadding.some(path => location.href.includes(path))

  return (
    <Route
      exact={exact}
      path={path}
      render={props => (
        <StyledContainer addMobileTopPadding={addMobileTopPadding}>
          <Component {...props} />
        </StyledContainer>
      )}
    />
  )
}

export const IndexWrapper: React.FC = () => {
  const { authStore, userStore, searchStore, environmentStore } = useContext(StoreContext)

  const containerRef = useRef();

  const onMessage = (event: MessageEvent) => {
    const data: InboundMessage = event.data

    if (!data || !data.type) {
      return
    }

    if (data.sender !== ID.OMAPOSTI) {
      return
    }

    switch (data.type) {
      case InboundMessageType.TOKENS:
        const tokens = data.payload
        if (tokens.encoded) {
          authStore.setAccessToken(tokens.encoded.accessToken)
          authStore.setIdToken(tokens.encoded.idToken)
          authStore.setRoleToken(tokens.encoded.roleToken)
          // TODO once auth store is loaded, fetch data for other stores

          environmentStore.fetchEnvironment()

          userStore.fetchUserData()
          // maybe this does not need to be loaded here, if there is a refactor with two links in the sidebar
          // also remove loading check
          searchStore.fetchUsersAndInvites()
          searchStore.fetchOrganisations()
        }
        break
      case InboundMessageType.UNMOUNT:
        // Use React's findDOMNode to get the root component to unmount.
        // This allows unmounting the feature root component even if the feature div is already removed from DOM by core.
        const featureElement = findDOMNode(containerRef.current)?.parentElement
        if (featureElement) {
          unmountComponentAtNode(featureElement)
        }
        break
    }
  }

  useEffect(() => {
    window.addEventListener('message', onMessage)

    sendMessage({ type: OutboundMessageType.READY, payload: null })

    return () => {
      window.removeEventListener('message', onMessage);
      // Remove i18n listeners on unmount
      i18n.removeListeners()
    }
  }, [])

  return <div ref={containerRef}><Index /></div>;
}

export const IndexComponent: React.FC= () => {
  const { userStore, searchStore } = useContext(StoreContext)

  if (userStore.error) {
    return (
      <ThemeProvider theme={extendedTheme}>
        <I18nextProvider i18n={i18nInstance}>
          <GlobalStyle />
          <GlobalStyleNormalization />
          <BrowserRouter>
            <ErrorComponent error="general" />
          </BrowserRouter>
        </I18nextProvider>
      </ThemeProvider>
    )
  }

  if (!searchStore.firstLoadDone) {
    return (
      <ThemeProvider theme={extendedTheme}>
        <ViewLoading />
      </ThemeProvider>
    )
  }

  let basename = getBasePath();

  return (
    <ThemeProvider theme={extendedTheme}>
      <I18nextProvider i18n={i18nInstance}>
        <GlobalStyle />
        <GlobalStyleNormalization />
        <BrowserRouter>
          <ErrorBoundary>
            <Switch>
              <NavRoute exact path={`${basename}/`} component={MainView} />
              <NavRoute exact path={`${basename}/invite/1`} component={InviteView} />
              <NavRoute exact path={`${basename}/invite/2`} component={InviteStep2} />
              <NavRoute exact path={`${basename}/invite/3`} component={InviteStep3} />
              <NavRoute exact path={`${basename}/invite/4`} component={InviteStep4} />
              <NavRoute exact path={`${basename}/invite/failure`} component={InviteFailure} />
              <NavRoute exact path={`${basename}/organisations`} component={OrganisationsView} />
              <NavRoute exact path={`${basename}/organisation/:id`} component={SingleOrgView} />
              <NavRoute exact path={`${basename}/user/:id`} component={UserDetailView} />
              <NavRoute exact path={`${basename}/invite-detail/:id`} component={InviteDetailsView} />
              <NavRoute exact path={`${basename}/edit-user/:userid/:orgid`} component={EditUserView} />
              <Route render={props => <ErrorComponent error="badUrl" />} />
            </Switch>
          </ErrorBoundary>
        </BrowserRouter>
      </I18nextProvider>
    </ThemeProvider>
  )
}

const Index = observer(IndexComponent)

const StyledContainer = styled.div<{ addMobileTopPadding?: boolean }>`
  ${({ addMobileTopPadding, theme }) => css`
    padding: ${({ theme }) => theme.spacing.lg}rem;
    display: flex;
    flex-direction: column;
    min-width: 468px;
    width: 100%;
    max-width: 1000px;

    min-height: 100%;
    flex: 1 0 0;
    padding-top: ${addMobileTopPadding ? '32px' : '0px'};
  `}
  @media ${breakpoint.mobile} {
    padding: 1rem;
    min-width: 304px;
  }
`
const StyledContainerMainView = styled.div`
  padding-left: ${({ theme }) => theme.spacing.lg}rem;
  display: flex;
  flex-direction: column;
  min-width: 468px;
  width: 100%;

  @media ${breakpoint.mobile} {
    padding: ${({ theme }) => theme.spacing.xxs}rem;
    min-width: 304px;
  }
  min-height: 100%;
  flex: 1 0 0;
`
