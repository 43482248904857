import React from 'react'
import styled, { css } from 'styled-components'

import { useTranslation } from 'react-i18next'
import { PostcardIcon } from '@postidigital/posti-components'
import { RouteComponentProps, withRouter } from 'react-router'
import { observer } from 'mobx-react-lite'
import { IInvite } from '../../store/dataModels/interfaces'
import { formatDate, getInviteState } from '../../utils'
import Theme from '@postidigital/posti-theme'
import { getCurrentBasePath } from '../../utils/helpers'

interface IProps {
  invite: IInvite
}

type Props = RouteComponentProps & IProps

export const MobileInviteListItemComponent: React.FC<Props> = ({ invite, history }) => {
  const { expired, email, expires, created, inviteId, cancelled, accepted } = invite
  const { t, i18n } = useTranslation()

  const goToInvite = () => {
    const basePath = getCurrentBasePath(i18n)
    history.push(`${basePath}/invite-detail/${inviteId}`)
  }

  const getPostCardIconColor = (): string => {
    const pink = Theme.color.brandPink
    const blue = Theme.color.brandSky

    if (cancelled) {
      return pink
    }

    if (expired) {
      return pink
    }

    return blue
  }

  return (
    <>
      <StyledBox onClick={goToInvite} isLast>
        <div>
          <PostcardIcon color={getPostCardIconColor()} fontSize="14px" width={'24px'} height={'24px'} />
        </div>

        <div>
          <StyledEmailText>{truncate(email, 30)}</StyledEmailText>
          <StyledNextToEachOther>
            <StyledCreatedText>{formatDate(created)}</StyledCreatedText>
            <StyledTimeExpired expired={expired}>
              {getInviteState(cancelled, expired, expires, accepted, t)}
            </StyledTimeExpired>
          </StyledNextToEachOther>
        </div>
      </StyledBox>
    </>
  )
}

export const MobileInviteListItem = withRouter(observer(MobileInviteListItemComponent))

const StyledTimeExpired = styled.div<{ expired: boolean }>(
  ({ theme, expired }) => css`
    font-family: ${theme.fontFamily.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 143%;
    color: ${expired ? theme.color.neutralPassiveGray : theme.color.neutralNetworkGray};
  `
)

const StyledNextToEachOther = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    align-items: center;
  `
)

const StyledEmailText = styled.div(
  ({ theme }) => css`
    font-family: ${theme.fontFamily.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 143%;
    color: ${theme.color.neutralBlack};
    vertical-align: middle;
    word-break: break-all;

    padding-left: 8px;
    padding-top: 3px;
    padding-bottom: 8px;
  `
)

const StyledCreatedText = styled.div(
  ({ theme }) => css`
    font-family: ${theme.fontFamily.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 143%;
    color: ${theme.color.neutralNetworkGray};
    padding-left: 8px;
    width: 120px;
  `
)

const StyledBox = styled.div<{ isLast: boolean }>(
  ({ theme, isLast }) => css`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    flex-grow: 3;
    height: 84px;
    background: #ffffff;

    padding-left: 16px;

    margin-bottom: 16px;
  `
)

function truncate(str, n) {
  return str.length > n ? str.substr(0, n - 1) + '...' : str
}
