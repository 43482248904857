import React from 'react'
import styled from 'styled-components'

export interface IProps {
  children: JSX.Element[] | JSX.Element | string
  label: string
}

export const Pane: React.FC<IProps> = props => {
  return <>{props.children}</>
}
