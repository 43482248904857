import styled from 'styled-components'
import { breakpoint } from './breakpoint'
import { DropDownType, IDropDownOption, INumberOption } from '../store/dataModels/interfaces'

/**
 * Show something only in desktop breakpoint
 */
export const OnlyInDesktop = styled.div`
  display: none;
  @media ${breakpoint.md} {
    display: flex;
    flex-shrink: 0;
  }
`

export const arrayIsEmpty = (array: Array<any>): boolean => {
  return Array.isArray(array) && array.length === 0
}

export const toDropDownOptions = (array: string[], options: IDropDownOption[]): IDropDownOption[] => {
  return array.map(number => {
    // Use option's label text
    let label = number
    let type: DropDownType
    if (options?.length) {
      let foundOption = options.find(v => v.value === number)
      label = foundOption?.label || number
      type = foundOption?.type || undefined
    }
    return { value: number, label: label, type: type }
  })
}

export const toStringArray = (array: IDropDownOption[]): string[] => {
  return array.map(obj => {
    return obj.value
  })
}

export const getBasePath = (): string => {
  const { basename } = document.getElementById('oneaccount-orgadmin-front').dataset
  if (!basename) return ''
  return basename
}

export const getCurrentBasePath = (i18n): string => {
  const currentLang = i18n.language
  const { basename } = document.getElementById('oneaccount-orgadmin-front').dataset
  if (!basename) return ''
  return basename.replace(':locale', currentLang)
}

export const addValuesToLabels = (numbers: INumberOption[], type: DropDownType): IDropDownOption[] => {
  return numbers.map(
    (number: INumberOption): IDropDownOption => {
      return {
        value: number.value,
        label: `${number.value} ${number.label}`,
        isDisabled: number.disabled,
        type: type,
      }
    }
  )
}

export const sortIDropDownOptionsByDisabledStatusAndValue = (
  a: IDropDownOption | null | undefined,
  b: IDropDownOption | null | undefined
): number => {
  if (a == null && b != null) return 1
  if (a != null && b == null) return -1
  if (a == null && b == null) return 0

  if (a.isDisabled && !b.isDisabled) return 1
  if (!a.isDisabled && b.isDisabled) return -1

  if (a.value == null && b.value != null) return 1
  if (a.value != null && b.value == null) return -1
  if (a.value == null && b.value == null) return 0

  let aNumber = parseInt(a.value, 10)
  let bNumber = parseInt(b.value, 10)
  if (isFinite(aNumber) && isFinite(bNumber)) {
    return aNumber - bNumber
  } else {
    return a!.value.localeCompare(b!.value)
  }
}
