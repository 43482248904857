import React, { useContext, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { TransProps, useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'
import { observer } from 'mobx-react-lite'
import { Button } from '@postidigital/posti-components'
import { sendPageInfo } from '@postidigital/posti-google-analytics'
import { StoreContext } from '../../store'
import ErrorLarge from '../../static/img/error_large'

import { getCurrentBasePath } from '../../utils/helpers'

type Props = Partial<TransProps> & RouteComponentProps

const InviteFailureComponent: React.FC<Props> = ({ history }) => {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    sendPageInfo({
      language: i18n.language,
      solution: 'oneaccount-orgadmin',
      domain: window.location.hostname,
      pagePath: `/org-admin/invite/failure`,
      pageName: 'oneaccount-orgadmin-invite',
    })
    // On component unmount clear store
    return function cleanup() {
      inviteStore.clearStore()
    }
  }, [])

  const { inviteStore } = useContext(StoreContext)

  let error = "general";
  error = "email_reserved";
  if (inviteStore.errorCode != "") {
    error = inviteStore.errorCode.toLowerCase();
  }
  if (! i18n.exists(`errors.invite_${error}.title`)) {
    error = "general";
  }

  return (
    <StyledErrorContainer>
      <ErrorLarge />
      <StyledTitle>{t(`errors.invite_${error}.title`)}</StyledTitle>
      {i18n.exists(`errors.invite_${error}.body`) && <StyledBodyText>{t(`errors.invite_${error}.body`)}</StyledBodyText>}
      <StyledButton
        css
        title={t(`errors.reload`)}
        onClick={() => {
          const basePath = getCurrentBasePath(i18n)
          history.push(`${basePath}/`)
          window.location.reload()
        }}
      >
        {t(`errors.refresh`)}
      </StyledButton>
    </StyledErrorContainer>
  )
}

const StyledButton = styled(Button)`
  margin-top: 32px;
`
export const InviteFailure = withRouter(observer(InviteFailureComponent))

const StyledErrorContainer = styled.div(
  ({ theme }) => css`
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 48px;
  `
)

const StyledTitle = styled.div(
  ({ theme }) => css`
    font-family: PostiSans;
    font-style: normal;
    font-weight: 500;
    font-size: 39px;
    line-height: 40px;
    letter-spacing: -0.01em;

    color: #000000;
    margin-top: 16px;

    text-align: center;
  `
)

const StyledBodyText = styled.div(
  ({ theme }) => css`
    font-family: ${theme.fontFamily.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    text-align: center;
    margin-top: 8px;
  `
)
