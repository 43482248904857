import React, { useContext, useEffect } from 'react'
import { TransProps, useTranslation } from 'react-i18next'
import { Redirect, RouteComponentProps, withRouter } from 'react-router'
import { observer } from 'mobx-react-lite'
import { StoreContext } from '../../store'
import {
  Stepper,
  InfoBox,
  NextButton,
  BackButton,
  SubTitle,
  InviteDropDown,
  RoleContractRequirements,
} from '../../components'
import { arrayIsEmpty, getCurrentBasePath } from '../../utils/helpers'
import styled from 'styled-components'
import { Headline } from '@postidigital/posti-components'
import { sendPageInfo } from '@postidigital/posti-google-analytics'
import { DropDownType } from '../../store/dataModels/interfaces'
import { sortIDropDownOptionsByDisabledStatusAndValue } from '../../utils/helpers'

type Props = Partial<TransProps> & RouteComponentProps

const InviteStep3Component: React.FC<Props> = ({ history }) => {
  const { t, i18n } = useTranslation()
  useEffect(() => {
    sendPageInfo({
      language: i18n.language,
      solution: 'oneaccount-orgadmin',
      domain: window.location.hostname,
      pagePath: `/org-admin/invite/3`,
      pageName: 'oneaccount-orgadmin-invite',
    })
  }, [])

  const { inviteStore, userStore, environmentStore } = useContext(StoreContext)

  const {
    customerNumbers,
    emailList,
    setSelectedCustomerNumbers,
    selectedCustomerNumbers,
    LSCNumbers,
    selectedLSCNumbers,
    setSelectedLSCNumbers,
    transportIds,
    selectedTransportIds,
    setSelectedTransportIds,
    businessPartnerNumbers,
    selectedBusinessPartnerNumbers,
    setSelectedBusinessPartnerNumbers,
    roleValues,
    selectedBusiness,
    pendingRequest,
    isLoading,
  } = inviteStore

  // Redirect user to beginning of flow if emails or selectedBusiness have not been set
  if (pendingRequest === '' && (emailList.length < 1 || !selectedBusiness.businessId)) {
    const basename = getCurrentBasePath(i18n)
    return <Redirect to={`${basename}/invite/1`} />
  }

  const getCustomerNumbers = () => {
    return businessPartnerNumbers
      .sort(sortIDropDownOptionsByDisabledStatusAndValue)
      .concat(customerNumbers.sort(sortIDropDownOptionsByDisabledStatusAndValue))
  }

  const selectCustomerNumbers = values => {
    setSelectedCustomerNumbers(values.filter(number => number.type === DropDownType.CUSTOMER_NUMBER))
    setSelectedBusinessPartnerNumbers(values.filter(number => number.type === DropDownType.BUSINESS_PARTNER_NUMBER))
  }

  const getSelectedCustomerNumbers = () => {
    return selectedBusinessPartnerNumbers
      .sort(sortIDropDownOptionsByDisabledStatusAndValue)
      .concat(selectedCustomerNumbers.sort(sortIDropDownOptionsByDisabledStatusAndValue))
  }

  const sendInvitePossible = (): boolean => {
    // Send not possible if old request is still pending
    if (isLoading) {
      return false
    }
    if (!customerNumbers && !LSCNumbers && !transportIds && !businessPartnerNumbers) {
      return true
    }

    const roles = userStore.getRolesThatRequireContracts(selectedBusiness.businessId, roleValues)

    const selectedTypes = []
    if (!arrayIsEmpty(selectedCustomerNumbers.slice())) {
      selectedTypes.push(DropDownType.CUSTOMER_NUMBER)
    }
    if (!arrayIsEmpty(selectedLSCNumbers.slice())) {
      selectedTypes.push(DropDownType.LOGISTICS_CONTRACT_NUMBER)
    }
    if (!arrayIsEmpty(selectedTransportIds.slice())) {
      selectedTypes.push(DropDownType.TRANSPORT_ID)
    }
    if (!arrayIsEmpty(selectedBusinessPartnerNumbers.slice())) {
      selectedTypes.push(DropDownType.BUSINESS_PARTNER_NUMBER)
    }

    let requirementsOk = true
    roles.forEach(role => {
      const requiredTypes = []
      if (role.customerNumberRequired) {
        requiredTypes.push(DropDownType.CUSTOMER_NUMBER)
      }
      if (role.logisticsContractNumberRequired) {
        requiredTypes.push(DropDownType.LOGISTICS_CONTRACT_NUMBER)
      }
      if (role.transportIdRequired) {
        requiredTypes.push(DropDownType.TRANSPORT_ID)
      }
      if (role.businessPartnerNumberRequired) {
        requiredTypes.push(DropDownType.BUSINESS_PARTNER_NUMBER)
      }

      const intersection = requiredTypes.filter(item => selectedTypes.indexOf(item) !== -1)
      if (intersection.length === 0) {
        requirementsOk = false
        return
      }
    })

    return requirementsOk
  }

  return (
    <>
      <Stepper />
      <InfoBox />

      {environmentStore.hasFeature('transportId') && (customerNumbers || LSCNumbers || transportIds || businessPartnerNumbers) && (
          <SubTitle>{t(`invite.customerNumbersContracts`)}</SubTitle>
        )}
      {!environmentStore.hasFeature('transportId') && (customerNumbers || LSCNumbers || transportIds || businessPartnerNumbers) && (
          <SubTitle>{t(`invite.customerNumbersContractsOld`)}</SubTitle>
        )}

      <RoleContractRequirements businessId={selectedBusiness.businessId} roleValues={roleValues} />

      <StyledDropDownsWrapper>
        {(customerNumbers || LSCNumbers || transportIds || businessPartnerNumbers) && (
          <StyledHeadline as="h3" size="Six">
            {t(`general.contractNumbers`)}
          </StyledHeadline>
        )}

        {((customerNumbers && customerNumbers.length > 0) ||
        (businessPartnerNumbers && businessPartnerNumbers.length > 0)) && (
          <InviteDropDown
            id="customer_number_dropdown"
            title={t(`general.selectCustomerNumbers`)}
            placeHolderText={t(`general.customerNumbers`)}
            options={getCustomerNumbers()}
            selectedOptions={getSelectedCustomerNumbers()}
            onChange={values => selectCustomerNumbers(values)}
          />
        )}
        {LSCNumbers && LSCNumbers.length > 0 && (
          <InviteDropDown
            id="lsc_number_dropdown"
            title={t(`general.selectLogisticsContractNumbers`)}
            placeHolderText={t(`general.LCSNumbers`)}
            options={LSCNumbers?.sort(sortIDropDownOptionsByDisabledStatusAndValue)}
            selectedOptions={selectedLSCNumbers}
            onChange={values => setSelectedLSCNumbers(values)}
          />
        )}
        {transportIds && transportIds.length > 0 && (
          <InviteDropDown
            id="transport_id_dropdown"
            title={t(`general.selectTransportIds`)}
            placeHolderText={t(`general.transportIds`)}
            options={transportIds?.sort(sortIDropDownOptionsByDisabledStatusAndValue)}
            selectedOptions={selectedTransportIds}
            onChange={values => setSelectedTransportIds(values)}
          />
        )}

        {/* TODO FIX ME by adding translations when needed <InviteDropDown placeHolderText="Client Numbers" /> */}
      </StyledDropDownsWrapper>
      <StyledButtonsWrapper>
        <BackButton
          onClick={() => {
            inviteStore.clearPendingRequest()
            inviteStore.clearStep3State()
            const basePath = getCurrentBasePath(i18n)
            history.push(`${basePath}/invite/2`)
          }}
        ></BackButton>

        <NextButton
          disabled={!sendInvitePossible()}
          customText={t(`invite.send`)}
          onClick={async () => {
            const basePath = getCurrentBasePath(i18n)
            inviteStore.postInviteToBackend(i18n.language)
            history.push(`${basePath}/invite/4`)
          }}
        />
      </StyledButtonsWrapper>
    </>
  )
}

export const InviteStep3 = withRouter(observer(InviteStep3Component))

const StyledDropDownsWrapper = styled('div')`
  margin-bottom: 24px;
`

const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
`

const StyledHeadline = styled(Headline)`
  margin-top: 16px;
`
