import React, { useState, useContext } from 'react'
import styled from 'styled-components'

import { RouteComponentProps, withRouter } from 'react-router'
import { observer } from 'mobx-react-lite'
import { IInvite } from '../../store/dataModels/interfaces'
import { InviteListItem } from '..'
import { checkIfMobile } from '../../utils/breakpoint'
import { ShowMoreButton, MobileInviteListItem } from '../../components'
import { StoreContext } from '../../store'

interface IProps {
  invites: IInvite[]
}

type Props = RouteComponentProps & IProps

export const InviteListComponent: React.FC<Props> = ({ invites }) => {
  const [visibleInvites, setVisibleInvites] = useState(3)
  const { layoutStore } = useContext(StoreContext)

  const handleAddInvites = () => {
    if (checkIfMobile()) {
      return setVisibleInvites(visibleInvites + 3)
    }
    return setVisibleInvites(visibleInvites + 20)
  }

  const handleHideInvite = () => {
    return setVisibleInvites(3)
  }

  const renderShowButton = () => {
    if (visibleInvites < invites.length) {
      return (
        <ShowMoreButton
          id="show_more_invites_button"
          onClick={() => {
            handleAddInvites()
          }}
          type="showMore"
        />
      )
    }

    if (visibleInvites !== 3) {
      return (
        <ShowMoreButton
          id="show_less_invites_button"
          onClick={() => {
            handleHideInvite()
          }}
          type="showLess"
        />
      )
    }
  }

  if (!layoutStore.isSmallMobile) {
    return (
      <StyledInviteListWrapper>
        <StyledTable>
          <tbody>
            {invites.slice(0, visibleInvites).map((invite, i) => {
              return <InviteListItem key={invite.inviteId} invite={invite} />
            })}
          </tbody>
        </StyledTable>

        {renderShowButton()}
      </StyledInviteListWrapper>
    )
  }

  return (
    <>
      {invites.slice(0, visibleInvites).map((invite, i) => {
        return <MobileInviteListItem key={invite.inviteId} invite={invite} />
      })}
      {renderShowButton()}
    </>
  )
}

export const InviteList = withRouter(observer(InviteListComponent))

const StyledTable = styled.table`
  width: 100%;
`

const StyledInviteListWrapper = styled('div')`
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
