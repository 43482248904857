import React from 'react'
import { Headline } from '@postidigital/posti-components'
import Theme from '@postidigital/posti-theme'

type Props = {
  children: string,
  as?: `div` | `h1` | `h2` | `h3` | `h4`,
}

export const MainTitle: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Headline as="h1" size="Four" color={Theme.color.neutralBlack}>
        {children}
      </Headline>
    </>
  )
}

export const SubTitle: React.FC<Props> = ({ children, as = `h2` }) => {
  return (
    <>
      <Headline as={as} size="Six" color="#000000">
        {children}
      </Headline>
    </>
  )
}
