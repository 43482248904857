import React, { useContext, useEffect, useState } from 'react'
import { TransProps, useTranslation } from 'react-i18next'
import { Redirect, RouteComponentProps, withRouter } from 'react-router'
import { observer } from 'mobx-react-lite'
import { sendPageInfo } from '@postidigital/posti-google-analytics'
import { StoreContext } from '../../store'
import { Stepper, InfoBox, UserSection, SelectRoles, NextButton, BackButton, SubTitle } from '../../components'
import { getCurrentBasePath } from '../../utils/helpers'

import styled from 'styled-components'

type Props = Partial<TransProps> & RouteComponentProps

const InviteStep2Component: React.FC<Props> = ({ history }) => {
  const { t, i18n } = useTranslation()
  useEffect(() => {
    sendPageInfo({
      language: i18n.language,
      solution: 'oneaccount-orgadmin',
      domain: window.location.hostname,
      pagePath: `/org-admin/invite/2`,
      pageName: 'oneaccount-orgadmin-invite',
    })
  }, [])

  const { inviteStore, userStore } = useContext(StoreContext)
  const { emailList, roleValues, selectedBusiness, toggleUserRole, pendingRequest, isLoading } = inviteStore

  // Redirect user to beginning of flow if emails or selectedBusiness have not been set
  if (pendingRequest === '' && (emailList.length < 1 || !selectedBusiness.businessId)) {
    const basename = getCurrentBasePath(i18n)
    return <Redirect to={`${basename}/invite/1`} />
  }

  const selectedOrganisation = userStore.user.organizations.find(org => org.businessId === selectedBusiness.businessId)

  const handleNextClick = async () => {
    await inviteStore.getCustomerNumbersAndServiceContracts()
    const basePath = getCurrentBasePath(i18n)
    history.push(`${basePath}/invite/3`)
  }

  return (
    <>
      <Stepper />
      <InfoBox />

      <SubTitle>{`3. ${t(`invite.userType`)}`}</SubTitle>
      <UserSection />

      <SubTitle>{t(`invite.accessRights`)}</SubTitle>

      <SelectRoles
        roleGroups={selectedOrganisation.roleGroups}
        selectedRoleValues={roleValues}
        toggleUserRole={toggleUserRole}
        filterInvalidRoles={true}
        hasCustomerNumbers={selectedOrganisation.customerNumbers.length > 0}
        hasLCSNumbers={selectedOrganisation.logisticsContractNumbers.length > 0}
        hasTransportIds={selectedOrganisation.transportIds.length > 0}
        infoTranslationID="general.roleRequirementInfo"
      />

      <StyledNextButtonsWrapper>
        <BackButton
          onClick={() => {
            inviteStore.clearPendingRequest()
            inviteStore.clearStep2State()
            const basePath = getCurrentBasePath(i18n)
            history.push(`${basePath}/invite/1`)
          }}
        ></BackButton>
        <NextButton loading={isLoading} disabled={roleValues.slice().length <= 0} onClick={handleNextClick} />
      </StyledNextButtonsWrapper>
    </>
  )
}

export const InviteStep2 = withRouter(observer(InviteStep2Component))

const StyledNextButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
`
