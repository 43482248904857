import React from 'react'
import { components } from 'react-select'
import { observer } from 'mobx-react-lite'
import Theme from '@postidigital/posti-theme'
import { IDropDownOption } from '../../../store/dataModels/interfaces'
import { DropdownMenuItem } from '@postidigital/posti-components'
import AllSelect from './AllSelect'

// How to style https://react-select.com/styles
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: Theme.color.neutralGray2,
    borderRadius: '4px',
    minHeight: '48px',
    '&:hover': { borderColor: `${Theme.color.signalBlue}` },
    border: state.isFocused ? `3px solid ${Theme.color.signalBlue}` : `3px solid ${Theme.color.neutralGray2}`,
    borderBottom: state.isFocused
      ? `3px solid ${Theme.color.signalBlue}`
      : `3px solid ${Theme.color.neutralPassiveGray}`,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontFamily: Theme.fontFamily.primary,
    color: Theme.color.neutralNetworkGray,
  }),

  menu: (provided, state) => ({
    ...provided,
    fontFamily: Theme.fontFamily.primary,
    color: Theme.color.neutralNetworkGray,
    zIndex: 9999,
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    fontFamily: Theme.fontFamily.primary,
    color: Theme.color.neutralNetworkGray,
    fontSize: `${Theme.fontSize.text.md}`,
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    fontFamily: Theme.fontFamily.primary,
    color: Theme.color.neutralNetworkGray,
    fontSize: `${Theme.fontSize.text.md}`,
    '&:hover': {
      backgroundColor: `inherit`,
      color: `inherit`,
      cursor: 'pointer',
    },
  }),
  indicatorSeparator: styles => ({ display: 'none' }),
}

// If you are using mobx, be sure to covert your options to JS
// before passing as props to this component
// const optionsToJs = toJS(options)
// const selectedOptionsToJs = toJS(selectedOptions)
interface IProps {
  options: IDropDownOption[]
  selectedOptions: IDropDownOption[]
  isMulti?: boolean
  placeHolderText?: string
  onChange?: (values: IDropDownOption[]) => any
  allowSelectAll?: boolean
  allOption: IDropDownOption // required, will brake if not passed
  // This disbles all options, you can still view them
  // do not pass a onChange function
  disabled?: boolean
  // if you want to disable a single option. You have to
  // pass the option with a disabled prop EG:
  // const options = [
  // [{label: "one", value: 1, disabled: true},
  // {label: "two", value: 2}]
  closeMenuOnSelect?: boolean
}

const DropdownComponent: React.FC<IProps> = ({
  options,
  onChange,
  selectedOptions,
  isMulti = false,

  placeHolderText = '',
  allowSelectAll = false,
  allOption,
  disabled,
  closeMenuOnSelect = true,
}) => {
  const handleChange = (selectedOption: IDropDownOption[]) => {
    if (!selectedOption) {
      return onChange([])
    }

    if (!Array.isArray(selectedOption)) {
      selectedOption = [selectedOption]
    }

    // Don't allow selection of disabled options
    selectedOption = selectedOption.filter(x => !x.isDisabled)

    return onChange(selectedOption)
  }

  const menuPortalTarget = document.getElementById('root')

  return (
    <AllSelect
      value={selectedOptions}
      onChange={handleChange}
      isMulti={isMulti}
      options={options}
      styles={customStyles}
      placeholder={placeHolderText}
      components={{
        MultiValue,
        ValueContainer,
        Option: renderOption,
      }}
      noOptionsMessage={() => null}
      hideSelectedOptions={false}
      menuPlacement="auto"
      menuPortalTarget={menuPortalTarget}
      allowSelectAll={allowSelectAll}
      allOption={allOption}
      closeMenuOnSelect={closeMenuOnSelect}
    />
  )
}

const renderOption = ({ value, label, isSelected, innerRef, innerProps, isDisabled }) => {
  return (
    <DropdownMenuItem ref={innerRef} {...innerProps} id={value} selected={isSelected} disabled={isDisabled}>
      {label}
    </DropdownMenuItem>
  )
}

const ValueContainer = ({ children, ...props }) => {
  const allOption = props.options[0]

  const currentValues = props.getValue()
  let toBeRendered = children
  if (currentValues.some(val => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]]
  }

  return <components.ValueContainer {...props}>{toBeRendered}</components.ValueContainer>
}

const MultiValue = props => {
  const allOption = props.options[0]

  let labelToBeDisplayed = `${props.data.label}`
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = allOption.label
  }

  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  )
}

export const Dropdown = observer(DropdownComponent)
