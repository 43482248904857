import { observable, action, runInAction } from 'mobx'
import { serviceAdapter } from '../service/serviceAdapter'
import { IInvite } from './dataModels/interfaces'
import { searchStore } from './searchStore'

export class InviteDetailsStore {
  @observable
  inviteDetail: IInvite

  @observable
  isLoading: boolean = true

  @observable
  fetchingStage: 'fetching' | 'resent' | 'cancelled'

  @observable
  selectedRoleValues: string[] = []

  @observable
  selectedCustomerNumbers: string[] = []

  @observable
  selectedLCNumbers: string[] = []

  @observable
  selectedTransportIds: string[] = []

  @observable
  selectedBusinessPartnerNumbers: string[] = []

  @action
  async fetchInviteData(inviteId: string): Promise<void> {
    try {
      runInAction(() => {
        this.isLoading = true
      })

      const response = await serviceAdapter.sendGetRequest(`/api/invites/${inviteId}`)

      if (response.status >= 400) {
        throw new Error('Bad response from server')
      }
      const data = await response.json()

      runInAction(() => {
        this.inviteDetail = data

        this.selectedRoleValues = data.roleValues

        this.selectedCustomerNumbers = data.selectedCustomerNumbers
        this.selectedLCNumbers = data.selectedLogisticsContractNumbers
        this.selectedTransportIds = data.selectedTransportIds
        this.selectedBusinessPartnerNumbers = data.selectedBusinessPartnerNumbers
        this.isLoading = false
      })
    } catch (err) {
      console.error(err)
    }
  }

  @action
  async resendInvite(inviteId: string): Promise<void> {
    try {
      runInAction(() => {
        this.fetchingStage = 'fetching'
      })

      const response = await serviceAdapter.sendPostRequest(
        `/api/invites/${inviteId}/resend?lang=${this.inviteDetail.language}`,
        {}
      )
      if (response.status >= 400) {
        throw new Error('Bad response from server')
      }
      const invite = await response.json()

      runInAction(() => {
        this.inviteDetail = invite
        this.fetchingStage = 'resent'
        searchStore.updateSingleInvite(invite, inviteId)
        this.delayedResetPendingStatus()
      })
    } catch (err) {
      console.error(err)
    }
  }

  @action
  async cancelInvite(inviteId: string): Promise<void> {
    try {
      runInAction(() => {
        this.fetchingStage = 'fetching'
      })

      const response = await serviceAdapter.sendPostRequest(`/api/invites/${inviteId}/cancel?`, {})
      if (response.status >= 400) {
        throw new Error('Bad response from server')
      }
      const invite = await response.json()
      runInAction(() => {
        this.inviteDetail = invite
        this.fetchingStage = 'cancelled'
        searchStore.updateSingleInvite(invite, inviteId)
        this.delayedResetPendingStatus()
      })
    } catch (err) {
      console.error(err)
    }
  }

  @action
  delayedResetPendingStatus() {
    setTimeout(() => {
      runInAction(() => {
        this.fetchingStage = null
      })
    }, 2500)
  }
}

export const inviteDetailsStore = new InviteDetailsStore()
