import { observable, action, computed } from 'mobx'

const DESKTOP_BREAKPOINT_WIDTH_PX = 768

/**
 * Store to handle layout related changes
 */
export class LayoutStore {
  @observable
  windowHeight: number = this.screenDimensions.height

  @observable
  windowWidth: number = this.screenDimensions.width

  @computed
  get isMobile() {
    return this.windowWidth <= DESKTOP_BREAKPOINT_WIDTH_PX
  }

  @computed
  get isDesktop() {
    return this.windowWidth > DESKTOP_BREAKPOINT_WIDTH_PX
  }

  @computed
  get isSmallMobile() {
    return this.windowWidth < 480
  }

  get screenDimensions() {
    const {
      outerWidth,
      innerWidth,
      outerHeight,
      innerHeight,
      screen: { availHeight, availWidth },
    } = window

    // AvailWidth & availHeight will have mixed values in multi-screen setups.
    // They work properly for most mobile clients however.
    return {
      width: (availWidth > innerWidth ? innerWidth : availWidth) || outerWidth,
      height: (availHeight > innerHeight ? innerHeight : availHeight) || outerHeight,
    }
  }

  constructor() {
    const setDimensions = () => {
      const { width, height } = this.screenDimensions
      this.setWindowHeight(height)
      this.setWindowWidth(width)
    }
    window.onresize = debounce(setDimensions, 250, false)
  }

  @action
  setWindowHeight = (height: number) => {
    this.windowHeight = height
  }

  @action
  setWindowWidth = (width: number) => {
    this.windowWidth = width
  }
}

export const layoutStore = new LayoutStore()

export function debounce(func, wait, immediate) {
  var timeout
  return function() {
    var context = this,
      args = arguments
    var later = function() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}
