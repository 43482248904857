import React, { useContext, useEffect } from 'react'
import { TransProps, useTranslation } from 'react-i18next'
import { Redirect, RouteComponentProps, withRouter } from 'react-router'
import { observer } from 'mobx-react-lite'
import { StoreContext } from '../../store'
import { Title } from '@postidigital/posti-components'
import { sendPageInfo } from '@postidigital/posti-google-analytics'
import { Stepper, AddAnotherButton, ReadyButton, FetchingAnimation, FetchingTitle } from '..'
import { getCurrentBasePath } from '../../utils/helpers'

import styled from 'styled-components'
import { CSATSelector } from '../general/CSATSelector'

type Props = Partial<TransProps> & RouteComponentProps

const InviteStep4Component: React.FC<Props> = ({ history }) => {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    sendPageInfo({
      language: i18n.language,
      solution: 'oneaccount-orgadmin',
      domain: window.location.hostname,
      pagePath: `/org-admin/invite/4`,
      pageName: 'oneaccount-orgadmin-invite',
    })
    // On component unmount clear store
    return function cleanup() {
      inviteStore.clearStore()
    }
  }, [])

  const { inviteStore } = useContext(StoreContext)
  const { isSuccess, isLoading, pendingRequest } = inviteStore

  if (pendingRequest === 'error') {
    const basename = getCurrentBasePath(i18n)
    return <Redirect to={`${basename}/invite/failure`} />
  }

  return (
    <>
      <Stepper />
      <FetchingTitle pendingRequest={pendingRequest} type="invite" />
      <StyledCentered>
        <FetchingAnimation pendingRequest={pendingRequest} />
      </StyledCentered>

      {isSuccess && <CSATSelector />}

      <StyledButtonsWrapper>
        <AddAnotherButton
          css
          disabled={isLoading}
          onClick={() => {
            inviteStore.clearStore()
            const basePath = getCurrentBasePath(i18n)
            history.push(`${basePath}/invite/1`)
          }}
        />

        <ReadyButton
          css
          disabled={isLoading}
          onClick={() => {
            inviteStore.clearStore()
            const basePath = getCurrentBasePath(i18n)
            history.push(`${basePath}/`)
          }}
        />
      </StyledButtonsWrapper>
    </>
  )
}

export const InviteStep4 = withRouter(observer(InviteStep4Component))

const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  margin-top: auto;
  padding-top: 24px;
`

const StyledCentered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 24px;
  width: fit-content;
`
