import React, { useContext } from 'react'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next'
import { Headline, ListItem } from '@postidigital/posti-components'
import { StoreContext } from '../../store'
import { RouteComponentProps, withRouter } from 'react-router'
import { observer } from 'mobx-react-lite'
import { WideCard } from './WideCard'
import { ViewLoading } from '../index'
import { getCurrentBasePath } from '../../utils/helpers'

interface IProps {
  businessId: string
}

type Props = IProps & RouteComponentProps

export const OrganisationDetailsComponent: React.FC<Props> = ({ businessId }) => {
  const { organisationStore } = useContext(StoreContext)
  const { mainUsers, isLoading } = organisationStore
  const { t, i18n } = useTranslation()

  if (isLoading) {
    return <ViewLoading />
  }

  const getOrgUserPath = (organizationUserId: string): string => {
    const basePath = getCurrentBasePath(i18n)
    return `${basePath}/user/${organizationUserId}`
  }

  const renderMainUsers = mainUsers => {
    return mainUsers.map(user => {
      return (
        <WideCard
          to={getOrgUserPath(user.organizationUserId)}
          key={user.organizationUserId}
          title={`${user.firstName} ${user.lastName}`}
          subtitle={user.email}
        />
      )
    })
  }

  return (
    <div>
      <StyledListItem title={businessId} subtitle={t(`general.businessId`)} size="md" />
      <StyledHeadline as="div" size="Four" color="#000000">
        {t(`general.mainUsers`)}
      </StyledHeadline>
      {renderMainUsers(mainUsers)}
    </div>
  )
}

export const OrganisationDetails = withRouter(observer(OrganisationDetailsComponent))

const StyledListItem = styled(ListItem)`
  margin-left: 16px;
  margin-top: 16px;
`

const StyledHeadline = styled(Headline)`
  margin-top: 32px;
  margin-bottom: 16px;
`
