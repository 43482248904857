import React, { useContext, useEffect } from 'react'
import { StoreContext } from '../../store'
import { Selection, SelectionTitle } from '@postidigital/posti-components'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { ViewLoading } from '../general/ViewLoading'

export const SelectOrganisationComponent: React.FC<{}> = () => {
  const { userStore, inviteStore } = useContext(StoreContext)
  const { user } = userStore
  const { organizations } = user || {}

  const { setSelectedBusiness, selectedBusiness } = inviteStore

  useEffect(() => {
    if (organizations && organizations.length == 1) {
      let name = organizations[0].name
      let businessId = organizations[0].businessId
      setSelectedBusiness({ name, businessId })
    }
  }, [organizations])

  if (userStore.isLoading) {
    return <ViewLoading />
  }

  return (
    <div>
      {organizations.map(org => {
        const { name, businessId } = org
        const isSelected = businessId === selectedBusiness.businessId || organizations.length == 1
        return (
          <StyledSelection key={businessId} scheme="gray" selected={isSelected}>
            <SelectionTitle
              selected={isSelected}
              onClick={() => {
                setSelectedBusiness({ name, businessId })
              }}
            >
              <span>{`${name} (${businessId})`}</span>
            </SelectionTitle>
          </StyledSelection>
        )
      })}
    </div>
  )
}

export const SelectOrganisationList = observer(SelectOrganisationComponent)

const StyledSelection = styled(Selection)`
  margin-top: 8px;
  margin-bottom: 8px;
`
