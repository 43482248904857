import React from 'react'
import { observer } from 'mobx-react-lite'
import { Loading } from '@postidigital/posti-components'
import { SuccessAnimation, ErrorAnimation } from '@postidigital/posti-components/build/animations'

interface IProps {
  pendingRequest: string
}

const FetchingAnimationComponent: React.FC<IProps> = ({ pendingRequest }) => {
  switch (pendingRequest) {
    case 'loading':
      return <Loading preset="brandPink" size="xl" />
    case 'success':
      return <SuccessAnimation style={{ width: '111px', height: '111px' }} />
    case 'error':
      return <ErrorAnimation style={{ width: '111px', height: '111px' }} />
  }
}

export const FetchingAnimation = observer(FetchingAnimationComponent)
