import React, { useContext, useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { observer } from 'mobx-react-lite'
import { StoreContext } from '../store'
import { ViewLoading, EditStep1, EditStep2, EditTopSection, EditStep3 } from '../components'

interface ReactRouterIdProp {
  userid: string
  orgid: string
}

type Props = RouteComponentProps<ReactRouterIdProp>

const EditUserViewComponent: React.FC<Props> = ({ match }) => {
  const { userDetailsStore } = useContext(StoreContext)

  useEffect(() => {
    const { firstName } = userDetailsStore
    // if userDetails have not been fetched, but the user still navigates
    // to this view, fetch the data

    if (!firstName) {
      const userId = match.params.userid
      userDetailsStore.fetchUserData(userId)
    }

    // cleanup
    return () => {
      userDetailsStore.setChangesMade(false)
      userDetailsStore.setStep(1)
      userDetailsStore.setPendingRequest('')
    }
  }, [])

  if (userDetailsStore.isLoading) {
    return <ViewLoading />
  }

  const organisationId = match.params.orgid
  const organisationData = userDetailsStore.userOrganizations.find(
    org => org.organization.businessId === organisationId
  )

  const { step } = userDetailsStore

  if (step === 1) {
    return (
      <>
        <EditTopSection />
        <EditStep1 organisationData={organisationData} />
      </>
    )
  }

  if (step === 2) {
    return (
      <>
        <EditTopSection />
        <EditStep2 organisationData={organisationData} />
      </>
    )
  }

  if (step === 3) {
    return (
      <>
        <EditTopSection />
        <EditStep3 organizationData={organisationData} />
      </>
    )
  }
}

export const EditUserView = withRouter(observer(EditUserViewComponent))
