import { observable, action, computed, toJS, runInAction } from 'mobx'

import { validateEmail } from '../utils/validation'
import { serviceAdapter } from '../service/serviceAdapter'
import { DropDownType, IDropDownOption } from './dataModels/interfaces'
import { arrayIsEmpty, addValuesToLabels } from '../utils/helpers'
import { searchStore } from './index'

interface IInviteUserStore {
  postInviteToBackend: (language: string) => Promise<void>
  setEmail: (email: string) => void
  toggleUserRole: (userRole: string) => void
  getCustomerNumbersAndServiceContracts: () => Promise<void>
}

interface IUserOption {
  id: string
  label: string
  value?: string
  defaultChecked?: boolean
}

interface ISelectedBusiness {
  name: string
  businessId: string
}

export class InviteUserStore implements IInviteUserStore {
  @observable
  selectedBusiness: ISelectedBusiness = { name: null, businessId: null }

  @observable
  email: string = ''

  @observable
  emailList: string[] = []

  @observable
  roleValues: string[] = []

  @observable
  selectAll: boolean = false

  @observable
  pendingRequest: string = ''

  @observable
  errorCode: string = ''

  @observable
  customerNumbers: IDropDownOption[] | null = null

  @observable
  selectedCustomerNumbers: IDropDownOption[] | null = []

  @observable
  LSCNumbers: IDropDownOption[] | null = null

  @observable
  selectedLSCNumbers: IDropDownOption[] = []

  @observable
  transportIds: IDropDownOption[] | null = null

  @observable
  selectedTransportIds: IDropDownOption[] = []

  @observable
  businessPartnerNumbers: IDropDownOption[] | null = null

  @observable
  selectedBusinessPartnerNumbers: IDropDownOption[] = []

  @action
  setSelectedBusiness = (selectedBusiness: { name: string; businessId: string }) => {
    if (selectedBusiness.businessId !== this.selectedBusiness?.businessId) {
      this.clearStore(true)
    }

    this.selectedBusiness = selectedBusiness
  }

  @action
  setEmail = (email: string) => {
    this.email = email
  }

  @action
  addEmail = async (email: string) => {
    try {
      const validEmail = await this.validateInviteEmail(email)
      if (validEmail && !this.emailList.includes(email)) {
        runInAction(() => {
          this.emailList.push(email)
          this.email = ''
        })
      }
    } catch (err) {
      console.log(err)
      return
    }
  }

  @computed get parsedEmail() {
    // Parse emails from <email> format
    const regex = /<[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])>/.exec(
      this.email
    )
    return regex ? this.email.slice(regex.index + 1).split('>')[0] : this.email
  }

  @action
  removeEmail = (email: string) => {
    if (this.emailList.includes(email)) {
      const index = this.emailList.indexOf(email)
      if (index > -1) {
        this.emailList.splice(index, 1)
      }
    }
  }

  @computed get isValidEmail() {
    return this.email ? validateEmail(this.parsedEmail) : true
  }

  @computed get isEmailValidationConflict() {
    return this.pendingRequest === 'EMAIL_CONFLICT'
  }

  @computed
  get isLoading() {
    return this.pendingRequest === 'loading'
  }

  @computed
  get isSuccess() {
    return this.pendingRequest === 'success'
  }

  @action
  toggleUserRole = (userRole: string) => {
    if (!this.roleValues.includes(userRole)) {
      return this.roleValues.push(userRole)
    }
    const newRoles = this.roleValues.filter(role => role !== userRole)
    this.roleValues = newRoles
  }

  @action
  toggleSelectAll = () => {
    if (this.selectAll) {
      return (this.selectAll = false)
    }
    this.selectAll = true
  }

  @action
  getCustomerNumbersAndServiceContracts = async () => {
    try {
      runInAction(() => {
        this.pendingRequest = 'loading'
      })

      const postObject = {
        roles: toJS(this.roleValues),
      }

      const response = await serviceAdapter.sendPostRequest(
        `/api/contract-numbers/${this.selectedBusiness.businessId}/available`,
        postObject
      )

      if (response.status >= 400) {
        runInAction(() => {
          this.pendingRequest = 'error'
        })
        throw new Error('Bad response from server')
        return
      }

      const data = await response.json()

      // format IDropDownOptions
      runInAction(() => {
        this.customerNumbers = []
        this.LSCNumbers = []
        this.transportIds = []
        this.businessPartnerNumbers = []

        if (!arrayIsEmpty(data.customerNumbers)) {
          this.customerNumbers = addValuesToLabels(data.customerNumbers, DropDownType.CUSTOMER_NUMBER)
        }

        if (!arrayIsEmpty(data.logisticsContractNumbers)) {
          this.LSCNumbers = addValuesToLabels(data.logisticsContractNumbers, DropDownType.LOGISTICS_CONTRACT_NUMBER)
        }

        if (!arrayIsEmpty(data.transportIds)) {
          this.transportIds = addValuesToLabels(data.transportIds, DropDownType.TRANSPORT_ID)
        }

        if (!arrayIsEmpty(data.businessPartnerNumbers)) {
          this.businessPartnerNumbers = addValuesToLabels(
            data.businessPartnerNumbers,
            DropDownType.BUSINESS_PARTNER_NUMBER
          )
        }

        this.pendingRequest = 'success'
      })
    } catch (err) {
      console.error(err)
      runInAction(() => {
        this.pendingRequest = 'error'
      })
    }
  }

  @action
  setSelectedCustomerNumbers = (selectedCustomerNumbers: IDropDownOption[]) => {
    this.selectedCustomerNumbers = selectedCustomerNumbers
  }

  @action
  setSelectedLSCNumbers = (selectedLSCNumbers: IDropDownOption[]) => {
    this.selectedLSCNumbers = selectedLSCNumbers
  }

  @action
  setSelectedTransportIds = (selectedTransportIds: IDropDownOption[]) => {
    this.selectedTransportIds = selectedTransportIds
  }

  @action
  setSelectedBusinessPartnerNumbers = (selectedBusinessPartnerNumbers: IDropDownOption[]) => {
    this.selectedBusinessPartnerNumbers = selectedBusinessPartnerNumbers
  }

  @action
  postInviteToBackend = async language => {
    try {
      runInAction(() => {
        this.pendingRequest = 'loading'
      })

      const postObject = {
        businessId: this.selectedBusiness.businessId,
        language: language.toUpperCase(),
        emails: toJS(this.emailList),
        roleValues: toJS(this.roleValues),
        customerNumbers: toJS(this.selectedCustomerNumbers)
          .map(object => object.value)
          .filter(string => string !== '*'),
        logisticsContractNumbers: toJS(this.selectedLSCNumbers)
          .map(object => object.value)
          .filter(string => string !== '*'),
        transportIds: toJS(this.selectedTransportIds)
          .map(object => object.value)
          .filter(string => string !== '*'),
        businessPartnerNumbers: toJS(this.selectedBusinessPartnerNumbers)
          .map(object => object.value)
          .filter(string => string !== '*'),
      }
      const response = await serviceAdapter.sendPostRequest('/api/invites/', postObject)
      if (response.status >= 400) {
        runInAction(() => {
          this.pendingRequest = 'error'
          throw new Error('Bad response from server')
        })
        return false
      }

      const data = await response.json()
      const wasInvitationSuccessfull = data.success
      runInAction(() => {
        this.errorCode = ''
        this.pendingRequest = wasInvitationSuccessfull ? 'success' : 'error'
        this.clearUserSelectedState()
        searchStore.clearFilters({ noFetch: false })
        searchStore.fetchOrganisations()
        if (!wasInvitationSuccessfull) {
          this.errorCode = data.errorCode
        }
      })
      return wasInvitationSuccessfull
    } catch (err) {
      console.error(err)
      runInAction(() => {
        this.clearUserSelectedState()
        this.pendingRequest = 'error'
      })
      return false
    }
  }

  @action
  validateInviteEmail = async (email: string) => {
    try {
      runInAction(() => {
        this.pendingRequest = 'loading'
      })

      const postObject = {
        email: toJS(email),
      }
      const response = await serviceAdapter.sendPostRequest('/api/invites/validate/email', postObject)
      if (response.status >= 400) {
        runInAction(() => {
          this.pendingRequest = 'error'
          throw new Error('Bad response from server')
        })
        return false
      }
      // Handle data
      const data = await response.json()
      runInAction(() => {
        if (!data.success) {
          this.pendingRequest = data.errorCode
          return
        }
        this.pendingRequest = 'success'
      })
      if (!data.success) {
        return false
      }
      return true
    } catch (err) {
      console.error(err)
      runInAction(() => {
        this.pendingRequest = 'error'
      })
      return false
    }
  }

  @action
  clearUserSelectedState = (ignoreEmailListReset?: boolean) => {
    this.clearStep1State(ignoreEmailListReset)
    this.clearStep2State()
    this.clearStep3State()
  }

  @action
  clearStep1State = (ignoreEmailListReset: boolean = false) => {
    this.selectedBusiness = { name: null, businessId: null }
    this.roleValues = []
    if (!ignoreEmailListReset) {
      this.emailList = []
    }
    this.email = ''
    this.selectAll = false
    this.errorCode = ''
  }
  @action
  clearStep2State = () => {
    this.customerNumbers = null
    this.LSCNumbers = null
    this.transportIds = null
    this.businessPartnerNumbers = null
    this.errorCode = ''
  }

  @action
  clearStep3State = () => {
    this.selectedCustomerNumbers = []
    this.selectedLSCNumbers = []
    this.selectedTransportIds = []
    this.selectedBusinessPartnerNumbers = []
    this.errorCode = ''
  }

  @action
  clearPendingRequest = () => {
    runInAction(() => {
      this.pendingRequest = ''
    })
  }

  @action
  clearStore = (ignoreEmailListReset?: boolean) => {
    this.clearPendingRequest()
    this.clearUserSelectedState(ignoreEmailListReset)
    this.errorCode = ''
  }
}

export const inviteStore = new InviteUserStore()
