import React from 'react'
import PropTypes from 'prop-types'
import { default as ReactSelect } from 'react-select'

const MySelect = props => {
  if (props.allowSelectAll) {
    if (props.grouped) {
      const flatOptions = props.options.reduce((accumulatedOptions, currentGroup) => {
        return accumulatedOptions.concat(currentGroup.options)
      }, [])

      return (
        <ReactSelect
          {...props}
          options={[props.allOption, ...props.options]}
          onChange={(selected, event) => {
            if (selected !== null && selected.length > 0) {
              // if the user selected select all
              if (selected[selected.length - 1].value === props.allOption.value) {
                return props.onChange([props.allOption, ...flatOptions])
              }

              let result = []

              // if all has been selected
              if (selected.length === flatOptions.length) {
                // if user clicked select all

                // if (!selected.some(option => option.value === props.allOption.value)) {
                //   return props.onChange([])
                // }

                // filter out the all option
                result = selected.filter(option => option.value !== props.allOption.value)
                if (event.action === 'select-option') {
                  result = [props.allOption, ...flatOptions]
                }
                return props.onChange(result)
              }
            }
            return props.onChange(selected)
          }}
        />
      )
    }

    return (
      <ReactSelect
        {...props}
        options={[props.allOption, ...props.options]}
        onChange={(selected, event) => {
          if (selected !== null && selected.length > 0) {
            if (selected[selected.length - 1].value === props.allOption.value) {
              return props.onChange([props.allOption, ...props.options])
            }
            let result = []
            if (selected.length === props.options.length) {
              if (event.action === 'select-option') {
                result = [props.allOption, ...props.options]
              }
              return props.onChange(result)
            }
          }
          return props.onChange(selected)
        }}
      />
    )
  }

  return <ReactSelect {...props} />
}

export default MySelect
