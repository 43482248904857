import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { TransProps, useTranslation } from 'react-i18next'
import { Notification } from '@postidigital/posti-components'
import { StoreContext } from '../../store'
import { observer } from 'mobx-react-lite'

type Props = Partial<TransProps>

const InviteNotificationComponent: React.FC<Props> = () => {
  const { t, i18n } = useTranslation()

  const { language } = i18n

  const { inviteStore } = useContext(StoreContext)
  const { pendingRequest } = inviteStore

  return (
    <StyledNotiWrapper>
      <Notification type={getNotiType(pendingRequest)}>
        <StyledSpan>{generateText(pendingRequest)}</StyledSpan>
      </Notification>
    </StyledNotiWrapper>
  )
}

export const InviteNotification = observer(InviteNotificationComponent)

const getNotiType = (pendingRequest: string) => {
  switch (pendingRequest) {
    case 'success':
      return 'success'
    case 'error':
      return 'alert'
    default:
      return 'warning'
  }
}

const generateText = (pendingRequest: string) => {
  const { t, i18n } = useTranslation()

  switch (pendingRequest) {
    case 'success':
      return t(`invite.success`)
    case 'error':
      return t(`errors.general.title`)
    case 'loading':
      return t(`general.loading`)
    default:
      return t(`invite.rolesRequired`)
  }
}

const StyledNotiWrapper = styled.span(
  ({ theme }) => css`
    margin-bottom: 1.5rem;
  `
)

const StyledSpan = styled.span(
  ({ theme }) => css`
    margin-left: 3rem;
  `
)
