import React from 'react'
import { observer } from 'mobx-react-lite'
import { Checkbox, Notification, Headline, Body } from '@postidigital/posti-components'
import styled from 'styled-components'
import { IRoleGroupModel, IOrganizationItem, IRoleModel } from '../../store/dataModels/interfaces'
import { useTranslation } from 'react-i18next'
import Theme from '@postidigital/posti-theme'

interface IProps {
  roleGroups: IRoleGroupModel[]
  selectedRoleValues: string[]
  toggleUserRole?: (role: string) => void
  disabled?: boolean
  filterStyles?: boolean
  filterInvalidRoles?: boolean
  hasCustomerNumbers?: boolean
  hasLCSNumbers?: boolean
  hasTransportIds?: boolean
  infoTranslationID?: string
}

const SelectRolesComponent: React.FC<IProps> = ({
  roleGroups,
  selectedRoleValues,
  toggleUserRole,
  disabled = false,
  filterStyles,
  filterInvalidRoles,
  hasCustomerNumbers,
  hasLCSNumbers,
  hasTransportIds,
  infoTranslationID
}) => {
  return (
    <>
      {roleGroups
        // Filter out main user role, as it's taken care of in another component
        .filter(roleGroup => {
          return roleGroup.value !== 'OrganizationAdministration'
        })
        // draw UI for the rest of the role groups
        .map(roleGroup => {
          if (filterStyles) {
            return (
              <FilterRoleGroup
                key={roleGroup.value}
                selectedRoleValues={selectedRoleValues}
                toggleUserRole={toggleUserRole}
                disabled={disabled}
                roleGroup={roleGroup}
              />
            )
          }

          return (
            <RoleGroup
              key={roleGroup.value}
              selectedRoleValues={selectedRoleValues}
              toggleUserRole={toggleUserRole}
              disabled={disabled}
              roleGroup={roleGroup}
              filterInvalidRoles={filterInvalidRoles}
              hasCustomerNumbers={hasCustomerNumbers}
              hasLCSNumbers={hasLCSNumbers}
              hasTransportIds={hasTransportIds}
              infoTranslationID={infoTranslationID}
            />
          )
        })}
    </>
  )
}

export const SelectRoles = observer(SelectRolesComponent)

interface IRoleGroupProps {
  roleGroup: IRoleGroupModel
  selectedRoleValues: string[]
  toggleUserRole?: (role: string) => void
  disabled?: boolean
  filterInvalidRoles?: boolean
  hasCustomerNumbers?: boolean
  hasLCSNumbers?: boolean
  hasTransportIds?: boolean
  infoTranslationID?: string
}

const RoleGroupComponent: React.FC<IRoleGroupProps> = ({
  roleGroup,
  selectedRoleValues,
  toggleUserRole,
  disabled,
  filterInvalidRoles,
  hasCustomerNumbers,
  hasLCSNumbers,
  hasTransportIds,
  infoTranslationID,
}) => {
  const { name, roles } = roleGroup

  const { t, i18n } = useTranslation()
  const currentLang = i18n.language

  const isValidRole = (role: IRoleModel) => {
    if (!filterInvalidRoles) {
      return true
    }
    if (!role.customerNumberRequired && !role.logisticsContractNumberRequired && !role.transportIdRequired) {
      return true
    }

    const requiredTypes = [
      ['C', role.customerNumberRequired],
      ['L', role.logisticsContractNumberRequired],
      ['T', role.transportIdRequired],
    ]
      .filter(item => item[1])
      .map(item => item[0])

    const existingTypes = [
      ['C', hasCustomerNumbers],
      ['L', hasLCSNumbers],
      ['T', hasTransportIds],
    ]
      .filter(item => item[1])
      .map(item => item[0])

    const intersection = requiredTypes.filter(item => existingTypes.indexOf(item) !== -1)
    return intersection.length > 0
  }

  const validRoles = roles.filter(role => isValidRole(role))

  const sortedRoles = validRoles.slice().sort((a: IRoleModel, b: IRoleModel) => {
    if (a.value.startsWith('opc_') && b.value.startsWith('opc_')) {
      // opc role handling.

      /*ordering:
      opc_create_shipments
      opc_track_shipments
      opc_access_reports
      opc_business_account
      opc_api_keys
       */
      if (a.value == b.value) {
        return 0
      }

      if (a.value == 'opc_create_shipments') {
        // create shipments is always first.
        return -1
      } else if (a.value == 'opc_track_shipments') {
        // access reports is after create shipments
        if (b.value == 'opc_create_shipments') {
          return 1
        }
        return -1
      } else if (a.value == 'opc_access_reports') {
        // track shipments is third -> if 2nd is create or access then they come first
        if (b.value == 'opc_create_shipments' || b.value == 'opc_track_shipments') {
          return 1
        }
        return -1
      } else if (a.value == 'opc_business_account') {
        // business account is last if the last is not api keys
        if (b.value == 'opc_api_keys') {
          return -1
        }
        return 1
      } else if (a.value == 'opc_api_keys') {
        // apikeys are always last.
        return 1
      } else return 0
    } else {
      // if not sorting opc don't do any sorting.
      return 0
    }
  })



  const selectedRolesRequireContractNumbers = (): boolean => {
    return roles
      .filter(role => isValidRole(role))
      .filter(role => selectedRoleValues.includes(role.value))
      .filter(role => (
        role.customerNumberRequired
        || role.logisticsContractNumberRequired
        || role.transportIdRequired
      )).length > 0
  }

  const showHelpText = !!(infoTranslationID && selectedRolesRequireContractNumbers());

  return (
    <StyledRoleWrapper>
      <StyledHeadline as="div" size="Six">
        {name[currentLang]}
      </StyledHeadline>
      {sortedRoles.map(role => {
          return (
            <StyledRoleRow key={role.value}>
              <Body as="span" size="Four">
                {role.name[currentLang]}
              </Body>
              <Checkbox
                id={`${role.value}_${selectedRoleValues.includes(role.value)}`}
                disabled={disabled || role.selectionDisabled}
                css
                large
                value="selectAll"
                checked={selectedRoleValues.includes(role.value)}
                onChange={e => toggleUserRole(role.value)}
              />
            </StyledRoleRow>
          )
        })}
      {showHelpText && (
        <StyledNotification showIcon={false} dismissible={false} type="informational">
          <StyledInfoText size="Four" as="p">
            {t(infoTranslationID)}
          </StyledInfoText>
        </StyledNotification>
      )}
    </StyledRoleWrapper>
  )
}

const RoleGroup = observer(RoleGroupComponent)

const StyledRoleWrapper = styled('div')`
  background-color: ${({ theme }) => theme.color.neutralWhite};
  margin-bottom: 24px;
`

const StyledRoleRow = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.color.neutralGray5};
  input[type="checkbox"]:checked:disabled + div {
    border-color: lightgray;
    background-color: lightgray;
  }
  input[type="checkbox"]:disabled + div {
    background-color: lightgray;
    border: none;
  }
`

const StyledHeadline = styled(Headline)`
  margin-top: 16px;
  margin-bottom: 8px;
`

const StyledInfoText = styled(Body)`
  margin: 0;
  font-weight: 600;
`

const StyledNotification = styled(Notification)`
  background-color: rgb(246, 247, 249);
  color: rgb(30, 50, 100);
`

const FilterRoleGroupComponent: React.FC<IRoleGroupProps> = ({
  roleGroup,
  selectedRoleValues,
  toggleUserRole,
  disabled,
}) => {
  const { name, roles } = roleGroup

  const { i18n } = useTranslation()
  const currentLang = i18n.language

  return (
    <StyledRoleWrapper className={`rg_${roleGroup.value}_wrapper`}>
      <StyledHeadline as="div" size="Six" color={Theme.color.neutralBlack}>
        {name[currentLang]}
      </StyledHeadline>
      {roles.map(role => {
        return (
          <StyledFilterRoleRow key={role.value} className={`r_${role.value}_wrapper`}>
            <Checkbox
              id={`${role.value}_${selectedRoleValues.includes(role.value)}`}
              className={`cb_role_${role.value}`}
              disabled={disabled}
              css
              value={role.value}
              checked={selectedRoleValues.includes(role.value)}
              onChange={e => toggleUserRole(role.value)}
            />
            <Body as="span" size="Four" className={`r_${role.value}_title`}>
              {role.name[currentLang]}
            </Body>
          </StyledFilterRoleRow>
        )
      })}
    </StyledRoleWrapper>
  )
}

const FilterRoleGroup = observer(FilterRoleGroupComponent)

const StyledFilterRoleRow = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
`

