import React, { useContext } from 'react'
import { TransProps, useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'
import { observer } from 'mobx-react-lite'
import { StoreContext } from '../../store'
import { BellIcon } from '@postidigital/posti-components'
import styled from 'styled-components'

interface IProps {
  businessId: string
  roleValues: string[]
}

type Props = Partial<TransProps> & IProps

const RoleContractRequirementsComponent: React.FC<Props> = ({ businessId, roleValues }) => {
  const { t, i18n } = useTranslation()

  const { userStore, environmentStore } = useContext(StoreContext)

  const transportIdEnabled = environmentStore.hasFeature('transportId')

  const getSelectedRoleRequirements = () => {
    let i = 0
    return userStore
      .getRolesThatRequireContracts(businessId, roleValues)
      .map(role => {
        let requirements = [
          ['general.requiresCustomerNumbers', role.customerNumberRequired || role.businessPartnerNumberRequired],
          ['general.requiresLogisticsContractNumbers', role.logisticsContractNumberRequired],
        ]
        if (transportIdEnabled) {
          requirements.push(['general.requiresTransportIds', role.transportIdRequired])
        }
        let missingMessage = requirements
          .filter(item => item[1])
          .map(item => t(item[0]))
          .join(', ')
        missingMessage = missingMessage.replace(/,\s([^,]+)$/, ' ' + t('general.or') + ' $1')
        return role.name[i18n.language.toLowerCase()] + ' ' + t('general.requires') + ' ' + missingMessage + '.'
      })
      .map(str => <StyledText key={i++}>{str}</StyledText>)
  }

  const requirements = getSelectedRoleRequirements()

  return requirements.length > 0 ? (
    <StyledNotification>
      <StyledNotiWrapper>
        <BellIcon />
      </StyledNotiWrapper>
      <StyledContentWrapper>
        <StyledTitle>{t(`general.missingContractNumber`)}</StyledTitle>
        {requirements}
        <StyledText>
          {transportIdEnabled && t(`general.contractInfo`)}
          {!transportIdEnabled && t(`general.contractInfoOld`)}
        </StyledText>
      </StyledContentWrapper>
    </StyledNotification>
  ) : (
    <></>
  )
}

export const RoleContractRequirements = observer(RoleContractRequirementsComponent)

const StyledNotification = styled('div')`
  width: 50;
  color: ${({ theme }) => theme.color.signalBlue};
  background-color: #e1ebf9;
  border-radius: 4px;
  border-left: 4px solid ${({ theme }) => theme.color.signalBlue};

  display: flex;
  flex-direction: row;
  margin-top: 16px;
`

const StyledContentWrapper = styled('div')`
  margin: 16px 16px 16px 8px;
  display: flex;
  flex-direction: column;
`

const StyledNotiWrapper = styled('div')`
  margin: 16px;
`

const StyledTitle = styled('div')`
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  /* brand / xyz navy */

  color: #1e3264;
  margin-bottom: 8px;
`

const StyledText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.primary};

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 143%;
  /* or 20px */

  /* brand / xyz navy */

  color: #1e3264;
`
