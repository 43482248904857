import React, { useContext } from 'react'
import { TransProps, useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'
import { observer } from 'mobx-react-lite'
import { StoreContext } from '../store'
import { WideCard, MainTitle, ViewLoading } from '../components'
import styled from 'styled-components'
import { getCurrentBasePath } from '../utils/helpers'

type Props = Partial<TransProps> & RouteComponentProps

const OrganisationsComponent: React.FC<Props> = ({ history }) => {
  const { t, i18n } = useTranslation()

  const { userStore } = useContext(StoreContext)
  const { user } = userStore

  if (userStore.isLoading) {
    return <ViewLoading />
  }

  const getOrgPath = (businessId: string): string => {
    const basePath = getCurrentBasePath(i18n)
    return `${basePath}/organisation/${businessId}`
  }

  return (
    <>
      <MainTitle>{t(`general.organisations`)}</MainTitle>
      <StyledOranisationsContainer>
        {user.organizations.map(({ name, businessId }) => {
          return <WideCard title={`${name} (${businessId})`} key={businessId} to={getOrgPath(businessId)} />
        })}
      </StyledOranisationsContainer>
    </>
  )
}

export const OrganisationsView = withRouter(observer(OrganisationsComponent))

const StyledOranisationsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
`
