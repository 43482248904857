import React, { useContext, useEffect } from 'react'
import { TransProps, useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'
import { observer } from 'mobx-react-lite'
import { MainTitle, OrganisationExpander, ViewLoading, InviteList, SubTitle, RemoveUserModal } from '../components'
import { ListItem, BinIcon, Title, Text, Button } from '@postidigital/posti-components'
import { StoreContext } from '../store'
import styled from 'styled-components'
import { arrayIsEmpty } from '../utils/helpers'
import { breakpoint } from '../utils/breakpoint'

interface ReactRouterIdProp {
  id: string
}

type Props = Partial<TransProps> & RouteComponentProps<ReactRouterIdProp>

const UserDetailViewComponent: React.FC<Props> = ({ match }) => {
  const [t] = useTranslation()
  const { userDetailsStore, layoutStore } = useContext(StoreContext)

  const { isLoading } = userDetailsStore

  useEffect(() => {
    const userId = match.params.id
    userDetailsStore.fetchUserData(userId)
  }, [])

  if (isLoading) {
    return <ViewLoading />
  }

  const { firstName, lastName, email, phone, userOrganizations, invites } = userDetailsStore

  return (
    <>
      <MainTitle>{t('editUser.title')}</MainTitle>
      <StyledDetailsWrapper>
        <StyledListItem id="user_details_firstname" title={firstName} subtitle={t('general.firstName')} size="md" />
        <StyledListItem id="user_details_lastname" title={lastName} subtitle={t('general.lastName')} size="md" />
        <StyledListItem id="user_details_username" title={email} subtitle={t('invite.userUsername')} size="md" />
        <StyledListItem id="user_details_phone" title={phone} subtitle={t('general.phone')} size="md" />
        {/* TODO check do we want customerId shown? Not now */}
        {/* <StyledListItem title={phone} subtitle={'ohine'} size="md" /> */}
      </StyledDetailsWrapper>

      <SubTitle>{t('general.organisations')}</SubTitle>

      {userOrganizations.map(org => {
        return (
          <StyledOrgExpanderWrapper key={org.organization.businessId}>
            <OrganisationExpander
              organization={org.organization}
              selectedRoleValues={org.organizationUser.selectedRoles}
              selectedCustomerNumbers={org.organizationUser.selectedCustomerNumbers}
              selectedLCNumbers={org.organizationUser.selectedLogisticsContractNumbers}
              selectedTransportIds={org.organizationUser.selectedTransportIds}
              selectedBusinessPartnerNumbers={org.organizationUser.selectedBusinessPartnerNumbers}
              editable
              accountId={org.organizationUser.accountId}
              userEmail={org.organizationUser.email}
            />

            {!layoutStore.isMobile && (
              <RemoveUserModal
                trigger={<StyledBinIcon id={`remove_from_org_${org.organization.businessId}_button`} />}
                businessId={org.organization.businessId}
                accountId={org.organizationUser.accountId}
                userEmail={org.organizationUser.email}
              />
            )}
          </StyledOrgExpanderWrapper>
        )
      })}

      {!arrayIsEmpty(invites) && <InviteList invites={invites} />}
    </>
  )
}

export const UserDetailView = withRouter(observer(UserDetailViewComponent))

const StyledBinIcon = styled(BinIcon)`
  margin-left: ${({ theme }) => theme.spacing.xs}rem;
  margin-top: 1.2rem;
`

const StyledDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
`
const StyledOrgExpanderWrapper = styled('div')`
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
`

const StyledListItem = styled(ListItem)`
  width: 50%;
  @media ${breakpoint.mobile} {
    width: 100%;
  }
`
