import React, { useState, useContext } from 'react'
import styled from 'styled-components'

import { RouteComponentProps, withRouter } from 'react-router'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { IOrganizationItem, DropDownType } from '../../../store/dataModels/interfaces'
import {
  Selection,
  SelectionTitle,
  SelectionBody,
  ChevronUpIcon,
  ChevronDownIcon,
  Headline,
} from '@postidigital/posti-components'
import Theme from '@postidigital/posti-theme'
import {
  MainUserRadioGroup,
  SelectRoles,
  EditButton,
  RemoveButton,
  RemoveUserModal,
  ExpanderContractNumberList,
} from '../..'
import { getCurrentBasePath, addValuesToLabels } from '../../../utils/helpers'
import { StoreContext } from '../../../store'

interface IProps {
  organization: IOrganizationItem
  selectedRoleValues: string[]
  toggleUserRole?: (role: string) => any
  selectedCustomerNumbers?: string[]
  selectedLCNumbers?: string[]
  selectedTransportIds?: string[]
  selectedBusinessPartnerNumbers?: string[]
  editable?: boolean
  accountId?: string
  userEmail?: string
}

type Props = RouteComponentProps & IProps

export const OrganisationExpanderComponent: React.FC<Props> = ({
  organization,
  selectedRoleValues,
  toggleUserRole,
  selectedCustomerNumbers,
  selectedLCNumbers,
  selectedTransportIds,
  selectedBusinessPartnerNumbers,
  editable,
  accountId,
  history,
  userEmail,
}) => {
  const { userDetailsStore, environmentStore } = useContext(StoreContext)
  const [isExpanded, setIsExpanded] = useState(false)
  const { t, i18n } = useTranslation()
  const {
    name,
    businessId,
    customerNumbers,
    logisticsContractNumbers,
    transportIds,
    businessPartnerNumbers,
  } = organization

  const userCustomerNumbers = customerNumbers.filter(c => selectedCustomerNumbers.includes(c.value))
  const userLCNumbers = logisticsContractNumbers.filter(c => selectedLCNumbers.includes(c.value))
  const userTransportIds = transportIds.filter(c => selectedTransportIds.includes(c.value))
  const userBusinessPartnerNumbers = businessPartnerNumbers.filter(c =>
    selectedBusinessPartnerNumbers.includes(c.value)
  )

  const goToEdit = () => {
    const basePath = getCurrentBasePath(i18n)
    history.push(`${basePath}/edit-user/${userDetailsStore.userId}/${businessId}`)
  }

  return (
    <Selection
      classNames={`background-color: ${Theme.color.neutralGray2};`}
      highLightBorder={false}
      scheme="gray"
      selected={isExpanded}
      style={{ width: '100%' }}
    >
      <StyledSelectionTitle
        showSelectedIcon={false}
        selected={isExpanded}
        aria-controls="content"
        aria-expanded={isExpanded}
        onClick={() => {
          setIsExpanded(!isExpanded)
        }}
      >
        <StyledHeadline as="div" size="Six" style={{}}>
          {`${name} (${businessId})`}
          {editable && <EditButton onClick={() => goToEdit()} id={`edit_button_${businessId}`} />}
        </StyledHeadline>
        {isExpanded ? (
          <StyledCevronUpIcon width="1.5em" height="1.5em" />
        ) : (
          <StyledCevronDownIcon width="1.5em" height="1.5em" />
        )}
      </StyledSelectionTitle>
      <SelectionBody id="content" selected={isExpanded}>
        {editable && (
          <StyledButtonsWrapper>
            <RemoveUserModal
              trigger={
                <div>
                  <RemoveButton id="remove_user_2" />
                </div>
              }
              businessId={organization.businessId}
              accountId={accountId}
              userEmail={userEmail}
            />
          </StyledButtonsWrapper>
        )}

        <StyledTopSectionWrapper>
          <RadioWrapper>
            <MainUserRadioGroup
              disabled
              isMainUser={selectedRoleValues.includes('MainUser')}
              toggleUserRole={() => toggleUserRole('MainUser')}
            />
          </RadioWrapper>
        </StyledTopSectionWrapper>

        <StyledSelectRolesWrapper>
          <SelectRoles
            roleGroups={organization.roleGroups}
            selectedRoleValues={selectedRoleValues}
            disabled
            infoTranslationID="general.roleRequirementInfo2"
          />
        </StyledSelectRolesWrapper>

        <StyledSelectRolesWrapper>
          {customerNumbers && (
            <ExpanderContractNumberList
              title={t(`general.customerNumbers`)}
              options={addValuesToLabels(userBusinessPartnerNumbers, DropDownType.BUSINESS_PARTNER_NUMBER).concat(
                addValuesToLabels(userCustomerNumbers, DropDownType.CUSTOMER_NUMBER)
              )}
            />
          )}
          {logisticsContractNumbers && (
            <ExpanderContractNumberList
              title={t(`general.LCSNumbers`)}
              options={addValuesToLabels(userLCNumbers, DropDownType.LOGISTICS_CONTRACT_NUMBER)}
            />
          )}
          {environmentStore.hasFeature('transportId') && transportIds && (
            <ExpanderContractNumberList
              title={t(`general.transportIds`)}
              options={addValuesToLabels(userTransportIds, DropDownType.TRANSPORT_ID)}
            />
          )}
        </StyledSelectRolesWrapper>
      </SelectionBody>
    </Selection>
  )
}

export const OrganisationExpander = withRouter(observer(OrganisationExpanderComponent))

const StyledCevronUpIcon = styled(ChevronUpIcon)`
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 200ms ease-in;
`

const StyledCevronDownIcon = styled(ChevronDownIcon)`
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 200ms ease-in;
`

const StyledSelectRolesWrapper = styled('div')`
  background-color: ${({ theme }) => theme.color.neutralWhite};
  margin-top: 32px;
  padding: 16px;
`

const StyledTopSectionWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const RadioWrapper = styled('div')`
  margin-top: -20px;
`

const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`

const StyledHeadline = styled(Headline)`
  display: flex;
  align-items: center;
  button {
    margin-left: ${({theme}) => theme.spacing.xs}rem;
  }
`

const StyledSelectionTitle = styled(SelectionTitle)`
  > div:first-child {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`
