import React, { useContext, useState } from 'react'
import { TransProps, useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'
import { observer } from 'mobx-react-lite'
import { ListItem, CheckmarkCircledIcon } from '@postidigital/posti-components'
import Theme from '@postidigital/posti-theme'
import { StoreContext } from '../../store'

import styled, { css } from 'styled-components'

type Props = Partial<TransProps> & RouteComponentProps

const ToggleableSuccessIcon: React.FC<{ checked: boolean }> = ({ checked }) => {
  if (checked) {
    return <CheckmarkCircledIcon height="1.5rem" color={Theme.color.signalGreen} />
  }

  return <CheckmarkCircledIcon color={Theme.color.neutralPassiveGray} height="1.5rem" />
}

const StepperComponent: React.FC<Props> = ({ history, match }) => {
  const { environmentStore } = useContext(StoreContext)
  const [t] = useTranslation()
  const step = parseInt(match.path[match.path.length - 1])

  return (
    <StyledStepperContainer>
      <StyledListItem
        size="lg"
        subtitle="Step 1"
        title={t(`invite.orgAndUsers`)}
        current={step === 1}
        checked={step > 1}
        rightContent={<ToggleableSuccessIcon checked={step > 1} />}
      />
      <StyledListItem
        size="lg"
        subtitle="Step 2"
        title={t(`invite.accessRights`)}
        current={step === 2}
        checked={step > 2}
        rightContent={<ToggleableSuccessIcon checked={step > 2} />}
      />
      <StyledListItem
        size="lg"
        subtitle="Step 3"
        title={
          environmentStore.hasFeature('transportId')
            ? t(`invite.customerNumbersContracts`)
            : t(`invite.customerNumbersContractsOld`)
        }
        current={step === 3}
        checked={step > 3}
        rightContent={<ToggleableSuccessIcon checked={step > 3} />}
      />
    </StyledStepperContainer>
  )
}

export const Stepper = withRouter(observer(StepperComponent))

const StyledListItem = styled(ListItem)<{
  current: boolean
  checked: boolean
}>(
  ({ theme, current, checked }) => css`
    border-bottom: 1px solid ${({ theme }) => theme.color.neutralPassiveGray};
    width: 33.333%;

    ${checked &&
      css`
        border-bottom: 4px solid ${theme.color.signalGreen};
        border-radius: 2px 2px 0px 0px;
      `}

    ${current &&
      css`
        border-bottom: 4px solid ${theme.color.signalBlue};
        border-radius: 2px 2px 0px 0px;
      `}
  `
)

const StyledStepperContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
`
