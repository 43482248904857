import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'
import { observer } from 'mobx-react-lite'
import { FilterDropdown, MainUserRadioGroup, FilterServicesList } from '../../components'
import { Headline } from '@postidigital/posti-components'
import { toJS } from 'mobx'
import { StoreContext } from '../../store'
import styled from 'styled-components'
import Theme from '@postidigital/posti-theme'
import { arrayIsEmpty } from '../../utils/helpers'
import { breakpoint } from '../../utils/breakpoint'
import { DropDownType, IDropDownOption } from '../../store/dataModels/interfaces'

type Props = RouteComponentProps

const FiltersComponent: React.FC<Props> = () => {
  const { searchStore } = useContext(StoreContext)

  const {
    searchMainUsers,
    organisationsOptions,
    selectedOrganisations,
    customerNumbers,
    selectedCustomerNumbers,
    LSCN,
    selectedLSCN,
    transportIds,
    selectedTransportIds,
    businessPartnerNumbers,
    selectedBusinessPartnerNumbers,
    toggleSearchMainUsers,
    setSelectedOrganisations,
    setSelectedCustomerNumbers,
    setSelectedLSCN,
    setSelectedTransportIds,
    setSelectedBusinessPartnerNumbers,
  } = searchStore

  const [t] = useTranslation()

  const orgsToJS = toJS(organisationsOptions)
  const selectedOrganisationsToJS = toJS(selectedOrganisations)

  const orgsSelected = !arrayIsEmpty(selectedOrganisationsToJS)

  const allOption = {
    label: t(`general.selectAll`),
    value: '*',
    type: DropDownType.SELECT_ALL,
  }

  const getCombinedCustomerNumbers = () => {
    const combinedNumbers = []
    const allNumbers = [...businessPartnerNumbers, ...customerNumbers]

    for (const { label, options } of allNumbers) {
      const existingEntry = combinedNumbers.find(item => item.label === label)

      if (existingEntry) {
        const uniqueOptions = new Set([...existingEntry.options, ...options])
        existingEntry.options = Array.from(uniqueOptions)
      } else {
        combinedNumbers.push({ label, options })
      }
    }

    return combinedNumbers
  }

  const setCombinedSelectedCustomerNumbers = (values: IDropDownOption[]) => {
    let enabledValues = values.filter(item => !item.isDisabled)
    setSelectedCustomerNumbers(enabledValues.filter(item => item.type === DropDownType.CUSTOMER_NUMBER))
    setSelectedBusinessPartnerNumbers(enabledValues.filter(item => item.type === DropDownType.BUSINESS_PARTNER_NUMBER))
  }

  return (
    <StyledFilters>
      <StyledSepparator />
      <StyledHeadline as="div" size="Five" color="#000000">
        {t(`filter.filter`)}
      </StyledHeadline>

      <MainUserRadioGroup
        small
        plurarTranslations
        horizontal
        isMainUser={searchMainUsers}
        toggleUserRole={() => toggleSearchMainUsers()}
      />

      <Headline as="div" size="Six" color={Theme.color.neutralBlack}>
        {t('filter.orgInfo')}
      </Headline>
      {/* TODO break thse into reusable own components ? */}
      <FilterDropdown
        id="organization_selector"
        options={orgsToJS}
        selectedOptions={selectedOrganisationsToJS}
        allowSelectAll
        isMulti
        allOption={allOption}
        placeHolderText={t('general.organisations')}
        onChange={values => {
          setSelectedOrganisations(values)
        }}
      />

      <FilterDropdown
        id="customer_number_selector"
        disabled={!orgsSelected}
        grouped
        options={getCombinedCustomerNumbers()}
        selectedOptions={toJS(selectedBusinessPartnerNumbers.concat(selectedCustomerNumbers))}
        allowSelectAll
        isMulti
        allOption={allOption}
        placeHolderText={t('general.customerNumbers')}
        onChange={values => setCombinedSelectedCustomerNumbers(values)}
      />

      <FilterDropdown
        id="lcsnumbers_selector"
        disabled={!orgsSelected}
        grouped
        options={LSCN}
        selectedOptions={toJS(selectedLSCN)}
        allowSelectAll
        isMulti
        allOption={allOption}
        placeHolderText={t('general.LCSNumbers')}
        onChange={values => setSelectedLSCN(values)}
      />

      <FilterDropdown
        id="transportid_selector"
        disabled={!orgsSelected}
        grouped
        options={transportIds}
        selectedOptions={toJS(selectedTransportIds)}
        allowSelectAll
        isMulti
        allOption={allOption}
        placeHolderText={t('general.transportIds')}
        onChange={values => setSelectedTransportIds(values)}
      />

      {orgsSelected && <FilterServicesList />}
    </StyledFilters>
  )
}

export const Filters = withRouter(observer(FiltersComponent))

// const StyledWrapper = styled('div')`
//   flex-grow: 0; /* do not grow   - initial value: 0 */
//   flex-shrink: 0; /* do not shrink - initial value: 1 */
//   flex-basis: 258px;
//   display: flex;
//   flex-direction: row;
// `

const StyledFilters = styled('div')`
  flex-grow: 0; /* do not grow   - initial value: 0 */
  flex-shrink: 0; /* do not shrink - initial value: 1 */
  flex-basis: 365px;
  width: 365px;
  padding-left: 32px;
  padding-right: 32px;
  @media ${breakpoint.sm} {
    padding-right: ${({ theme }) => theme.spacing.xs}rem;
    padding-left: ${({ theme }) => theme.spacing.xs}rem;
  }
`

const StyledSepparator = styled('div')`
  width: 16px;
  background: ${({ theme }) => theme.color.neutralGray2};
  box-shadow: inset 0px 4px -4px rgba(0, 0, 0, 0.25);
`

const StyledHeadline = styled(Headline)`
  margin-top: ${({ theme }) => theme.spacing.lg}rem;
`
