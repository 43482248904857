import React, { useContext, useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { observer } from 'mobx-react-lite'
import { StoreContext } from '../store'
import { OrganisationDetails, UserDetails, InviteDetails, MainTitle, ViewLoading } from '../components'
import { useTranslation } from 'react-i18next'

import { Tabs, Pane } from '../components/organisation/Tabs'

interface ReactRouterIdProp {
  id: string
}

type Props = RouteComponentProps<ReactRouterIdProp>

const SingleOrgComponent: React.FC<Props> = ({ match }) => {
  const { userStore, organisationStore } = useContext(StoreContext)
  const orgId = match.params.id

  const { t } = useTranslation()

  useEffect(() => {
    organisationStore.initalFetch(orgId)
  }, [])

  const { user } = userStore

  if ((userStore.isLoading, organisationStore.isLoading)) {
    return <ViewLoading />
  }

  const organisation = user.organizations.find(org => org.businessId === orgId)
  const { name } = organisation

  return (
    <>
      <MainTitle>{name}</MainTitle>
      <Tabs>
        <Pane label={t(`general.orgDetails`)}>
          <OrganisationDetails businessId={orgId} />
        </Pane>
        <Pane label={t(`general.users`)}>
          <UserDetails businessId={orgId} />
        </Pane>
        <Pane label={t(`general.invites`)}>
          <InviteDetails />
        </Pane>
      </Tabs>
    </>
  )
}

export const SingleOrgView = withRouter(observer(SingleOrgComponent))
