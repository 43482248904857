import React, { ReactElement } from 'react'
import { TransProps, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Dropdown, Tooltip } from '../..'
import { Body, Headline } from '@postidigital/posti-components'
import styled from 'styled-components'
import { toJS } from 'mobx'
import { DropDownType, IDropDownOption } from '../../../store/dataModels/interfaces'

interface IProps {
  title: string
  options?: IDropDownOption[]
}

type Props = TransProps & IProps

export const ExpanderContractNumberListComponent: React.FC<Props> = ({ title, options }) => {
  const { t } = useTranslation()

  return (
    <>
      <StyledWrapper>
        <StyledHeadline as="div" size="Six">
          {title}
        </StyledHeadline>
        {options.map(option => (
          <StyledRow key={option.value}>
            <Body as="span" size="Four">
              {option.label}
              {option.type === DropDownType.BUSINESS_PARTNER_NUMBER && ' (' + t(`general.new`) + ')'}
            </Body>
          </StyledRow>
        ))}
      </StyledWrapper>
    </>
  )
}

export const ExpanderContractNumberList = observer(ExpanderContractNumberListComponent)

const StyledWrapper = styled('div')`
  background-color: ${({ theme }) => theme.color.neutralWhite};
  margin-bottom: 24px;
`

const StyledRow = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.color.neutralGray5};
`

const StyledHeadline = styled(Headline)`
  margin-top: 16px;
  margin-bottom: 8px;
`
