import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import styled, { css } from 'styled-components'

export const CustomerNumberToolTipInfoComponent: React.FC<{}> = () => {
  const [t] = useTranslation()

  return (
    <>
      <StyledToolTipContent>{t(`cnToolTipContent.firstPart`)}</StyledToolTipContent>
      <StyledToolTipTitle>{t(`cnToolTipContent.title`)}</StyledToolTipTitle>
      <StyledToolTipContent>
        <StyledUL>
          <li>{t(`cnToolTipContent.list1`)}</li>
          <li>{t(`cnToolTipContent.list2`)}</li>
          <li>{t(`cnToolTipContent.list3`)}</li>
          <li>{t(`cnToolTipContent.list4`)}</li>
          <li>{t(`cnToolTipContent.list5`)}</li>
          <li>{t(`cnToolTipContent.list6`)}</li>
          <li>{t(`cnToolTipContent.list7`)}</li>
          <li>{t(`cnToolTipContent.list8`)}</li>
          <li>{t(`cnToolTipContent.list9`)}</li>
        </StyledUL>
      </StyledToolTipContent>
    </>
  )
}

export const CustomerNumberToolTipInfo = observer(CustomerNumberToolTipInfoComponent)

const StyledUL = styled.ul(
  ({ theme }) => css`
    padding: 0px;
    padding-left: 18px;
  `
)

const StyledToolTipTitle = styled.div(
  ({ theme }) => css`
    padding: 4px;
    font-family: ${theme.fontFamily.primary};
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: ${theme.color.brandNavy};
  `
)

const StyledToolTipContent = styled.div(
  ({ theme }) => css`
    padding: 4px;
    font-family: ${theme.fontFamily.primary};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-family: ${theme.fontFamily.primary};

    color: ${theme.color.brandNavy};
  `
)
