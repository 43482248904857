import React from 'react'
import styled, { css } from 'styled-components'

import { useTranslation } from 'react-i18next'
import { PostcardIcon, ArrowRightIcon } from '@postidigital/posti-components'
import { RouteComponentProps, withRouter } from 'react-router'
import { observer } from 'mobx-react-lite'
import { IInvite } from '../../store/dataModels/interfaces'
import { formatDate, getInviteState } from '../../utils'
import Theme from '@postidigital/posti-theme'
import { getCurrentBasePath } from '../../utils/helpers'

interface IProps {
  invite: IInvite
}

type Props = RouteComponentProps & IProps

export const InviteListItemComponent: React.FC<Props> = ({ invite, history }) => {
  const { expired, email, expires, created, inviteId, cancelled, accepted } = invite
  const { t, i18n } = useTranslation()

  const goToInvite = () => {
    const basePath = getCurrentBasePath(i18n)
    history.push(`${basePath}/invite-detail/${inviteId}`)
  }

  const getPostCardIconColor = (): string => {
    const pink = Theme.color.brandPink
    const blue = Theme.color.brandSky

    if (accepted) {
      return blue
    }

    if (cancelled) {
      return pink
    }

    if (expired) {
      return pink
    }

    return blue
  }

  return (
    <StyledTR onClick={goToInvite}>
      <StyledEmailText>
        <PostcardIcon color={getPostCardIconColor()} fontSize="14px" width={'24px'} height={'24px'} />
        <StyledSpan>{truncate(email, 30)}</StyledSpan>
      </StyledEmailText>
      <StyledCreatedText>{formatDate(created)}</StyledCreatedText>
      <StyledTimeExpired expired={expired}>
        {getInviteState(cancelled, expired, expires, accepted, t)}
      </StyledTimeExpired>

      <StyledArrowWrapper>
        <ArrowRightIcon width="28px" />
      </StyledArrowWrapper>
    </StyledTR>
  )
}

export const InviteListItem = withRouter(observer(InviteListItemComponent))

const StyledTR = styled.tr(
  ({ theme }) => css`
    height: 48px;
    cursor: pointer;
  `
)

function truncate(str, n) {
  return str.length > n ? str.substr(0, n - 1) + '...' : str
}

const StyledSpan = styled.span(
  ({ theme }) => css`
    margin-left 8px;
  `
)

const StyledEmailText = styled.td(
  ({ theme }) => css`
    font-family: ${theme.fontFamily.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 143%;
    color: ${theme.color.neutralBlack};
    vertical-align: middle;
    word-break: break-all;
  `
)

const StyledCreatedText = styled.td(
  ({ theme }) => css`
    font-family: ${theme.fontFamily.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 143%;
    color: ${theme.color.neutralNetworkGray};

    margin-left: 54px;
  `
)

const StyledTimeExpired = styled.td<{ expired: boolean }>(
  ({ theme, expired }) => css`
    font-family: ${theme.fontFamily.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 143%;
    color: ${expired ? theme.color.neutralPassiveGray : theme.color.neutralNetworkGray};
  `
)

const StyledArrowWrapper = styled.td(
  ({ theme }) => css`
    font-family: ${theme.fontFamily.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 143%;
    text-align: right;
  `
)
