import React, { useContext, useEffect } from 'react'
import { TransProps, useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { StoreContext } from '../store'
import { EmailsSection, SelectOrganisationList, NextButton, MainTitle, SubTitle, Stepper } from '../components'
import { BellIcon } from '@postidigital/posti-components'
import { sendPageInfo } from '@postidigital/posti-google-analytics'
import { getCurrentBasePath } from '../utils/helpers'

type Props = Partial<TransProps> & RouteComponentProps

const InviteViewComponent: React.FC<Props> = ({ history }) => {
  const { t, i18n } = useTranslation()
  useEffect(() => {
    sendPageInfo({
      language: i18n.language,
      solution: 'oneaccount-orgadmin',
      domain: window.location.hostname,
      pagePath: `/org-admin/invite/1`,
      pageName: 'oneaccount-orgadmin-invite',
    })
  }, [])

  const { inviteStore } = useContext(StoreContext)

  const { emailList, selectedBusiness } = inviteStore

  return (
    <>
      <Stepper />
      <MainTitle>{t(`invite.inviteUser`)}</MainTitle>

      <StyledSubTitleWrapper>
        <SubTitle>{t(`invite.selectOrg`)}</SubTitle>
      </StyledSubTitleWrapper>

      <SelectOrganisationList />

      <StyledSubTitleWrapper>
        <SubTitle>{t(`invite.addEmail`)}</SubTitle>
      </StyledSubTitleWrapper>

      <EmailsSection />

      <StyledNextButtonWrapper>
        <StyledNotification>
          <StyledNotiWrapper>
            <BellIcon />
          </StyledNotiWrapper>
          <StyledContentWrapper>
            <StyledTitle>{t(`invite.invPeriod`)}</StyledTitle>
            <StyledText>{t(`invite.invInfo`)}</StyledText>
          </StyledContentWrapper>
        </StyledNotification>

        <StyledWrapForMarginMobile>
          <NextButton
            disabled={emailList.length < 1 || !selectedBusiness.businessId}
            onClick={() => {
              const basePath = getCurrentBasePath(i18n)
              history.push(`${basePath}/invite/2`)
            }}
          />
        </StyledWrapForMarginMobile>
      </StyledNextButtonWrapper>
    </>
  )
}

export const InviteView = withRouter(observer(InviteViewComponent))

const StyledSubTitleWrapper = styled('div')`
  margin-top: 40px;
`
const StyledWrapForMarginMobile = styled('div')`
  margin-top: 16px;
`

const StyledNotification = styled('div')`
  width: 50;
  color: ${({ theme }) => theme.color.signalBlue};
  background-color: #e1ebf9;
  border-radius: 4px;
  border-left: 4px solid ${({ theme }) => theme.color.signalBlue};
  width: 512px;

  display: flex;
  flex-direction: row;
  margin-top: 16px;
`

const StyledNotiWrapper = styled('div')`
  margin: 16px;
`

const StyledTitle = styled('div')`
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  /* brand / xyz navy */

  color: #1e3264;
  margin-bottom: 8px;
`

const StyledContentWrapper = styled('div')`
  margin: 16px 16px 16px 8px;
`

const StyledNextButtonWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: auto;
  flex-wrap: wrap;
  margin-top: -16px;
`

const StyledText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.primary};

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 143%;
  /* or 20px */

  /* brand / xyz navy */

  color: #1e3264;
`
