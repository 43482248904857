import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { CheckmarkCircledIcon, ProfileIcon, Headline } from '@postidigital/posti-components'
import Theme from '@postidigital/posti-theme'
import { StoreContext } from '../../store'
import styled from 'styled-components'
import { useParams } from 'react-router'

const EditInfoHeaderComponent: React.FC<{}> = ({}) => {
  const { userDetailsStore } = useContext(StoreContext)

  const params = useParams<{orgid: string}>()

  const organisationId = params.orgid
  const organisationData = userDetailsStore.userOrganizations.find(
    org => org.organization.businessId === organisationId
  )

  const name = organisationData?.organization?.name
  const businessId = organisationData?.organization?.businessId
  const email = organisationData?.organizationUser?.email;

  return (
    <StyledInfoBox>
      <StyledContentWrapper>
        <StyledHeadline as="h2" size="Six">{`${name} (${businessId})`}</StyledHeadline>
        {email && (
          <StyledText as="p">
            <StyledProfileIcon aria-hidden="true" />
            {email}
          </StyledText>
        )}
      </StyledContentWrapper>
      <StyledIconWrapper>
        <CheckmarkCircledIcon color={Theme.color.signalBlue} height="1.5rem" />
      </StyledIconWrapper>
    </StyledInfoBox>
  )
}

export const EditInfoHeader = observer(EditInfoHeaderComponent)

const StyledHeadline = styled(Headline)`
  margin-right: 8px;
  margin-bottom: 16px;
`

const StyledProfileIcon = styled(ProfileIcon)`
  margin-right: 8px;
`

const StyledInfoBox = styled('div')`
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.color.signalBlue};
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
`

const StyledIconWrapper = styled('div')`
  margin: 16px;
  margin-left: auto;
`

const StyledContentWrapper = styled('div')`
  margin: 16px 16px 16px 16px;
`

const StyledText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.primary};

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 143%;

  display: flex;
  align-items: center;

  color: #000000;
`
