import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { TransProps, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { sendPageInfo } from '@postidigital/posti-google-analytics'
import { StoreContext } from '../../store'
import { InviteDropDown, NextButton, BackButton, SubTitle, RoleContractRequirements } from '../../components'
import { DropDownType, IUserDetailOrganisation } from '../../store/dataModels/interfaces'
import {
  toDropDownOptions,
  arrayIsEmpty,
  toStringArray,
  sortIDropDownOptionsByDisabledStatusAndValue,
} from '../../utils/helpers'
import { breakpoint } from '../../utils/breakpoint'
import { Headline } from '@postidigital/posti-components'
import { useParams } from 'react-router'
import i18n from '../../utils/i18n'


interface IProp {
  organisationData: IUserDetailOrganisation
}

type Props = IProp & Partial<TransProps>

const EditStep2Component: React.FC<Props> = ({ organisationData }) => {
  const { userDetailsStore, userStore, environmentStore } = useContext(StoreContext)
  const { orgid, userid } = useParams()

  const { t } = useTranslation()

  useEffect(() => {
    sendPageInfo({
      language: i18n.getLocale,
      solution: 'oneaccount-orgadmin',
      domain: window.location.hostname,
      pagePath: `/org-admin/edit-user/${userid}/${orgid}/2`,
      pageName: 'oneaccount-orgadmin-edit-users',
    })
  }, [])

  const { organizationUser, organization } = organisationData
  const {
    customerNumbers,
    setSelectedCustomerNumbers,
    LSCNumbers,
    setSelectedLSCNumbers,
    transportIds,
    setSelectedTransportIds,
    businessPartnerNumbers,
    setSelectedBusinessPartnerNumbers,
  } = userDetailsStore

  const { user } = userStore

  const {
    selectedRoles,
    selectedCustomerNumbers,
    selectedLogisticsContractNumbers,
    selectedTransportIds,
    selectedBusinessPartnerNumbers,
  } = organizationUser

  const getCustomerNumbers = () => {
    return businessPartnerNumbers
      .sort(sortIDropDownOptionsByDisabledStatusAndValue)
      .concat(customerNumbers.sort(sortIDropDownOptionsByDisabledStatusAndValue))
  }

  const selectCustomerNumbers = values => {
    setSelectedCustomerNumbers(
      organization.businessId,
      toStringArray(values.filter(number => number.type === DropDownType.CUSTOMER_NUMBER))
    )
    setSelectedBusinessPartnerNumbers(
      organization.businessId,
      toStringArray(values.filter(number => number.type === DropDownType.BUSINESS_PARTNER_NUMBER))
    )
  }

  const sendChangesPossible = (): boolean => {
    // if there are no customer numbers or lcs numbers
    if (!customerNumbers && !LSCNumbers && !transportIds) {
      return true
    }

    const roles = userStore.getRolesThatRequireContracts(organization.businessId, selectedRoles)
    
    const selectedTypes = []
    if (!arrayIsEmpty(selectedCustomerNumbers.slice())) {
      selectedTypes.push(DropDownType.CUSTOMER_NUMBER)
    }
    if (!arrayIsEmpty(selectedLogisticsContractNumbers.slice())) {
      selectedTypes.push(DropDownType.LOGISTICS_CONTRACT_NUMBER)
    }
    if (!arrayIsEmpty(selectedTransportIds.slice())) {
      selectedTypes.push(DropDownType.TRANSPORT_ID)
    }
    if (!arrayIsEmpty(selectedBusinessPartnerNumbers.slice())) {
      selectedTypes.push(DropDownType.BUSINESS_PARTNER_NUMBER)
    }

    let requirementsOk = true
    roles.forEach(role => {
      const requiredTypes = []
      if (role.customerNumberRequired) {
        requiredTypes.push(DropDownType.CUSTOMER_NUMBER)
      }
      if (role.logisticsContractNumberRequired) {
        requiredTypes.push(DropDownType.LOGISTICS_CONTRACT_NUMBER)
      }
      if (role.transportIdRequired) {
        requiredTypes.push(DropDownType.TRANSPORT_ID)
      }
      if (role.businessPartnerNumberRequired) {
        requiredTypes.push(DropDownType.BUSINESS_PARTNER_NUMBER)
      }

      const intersection = requiredTypes.filter(item => selectedTypes.indexOf(item) !== -1)
      if (intersection.length === 0) {
        requirementsOk = false
        return
      }
    })

    return requirementsOk
  }

  return (
    <>
      {environmentStore.hasFeature('transportId') && (customerNumbers || LSCNumbers || transportIds) && (
        <SubTitle as="h3">{t(`invite.customerNumbersContracts`)}</SubTitle>
      )}
      {!environmentStore.hasFeature('transportId') && (customerNumbers || LSCNumbers || transportIds) && (
        <SubTitle as="h3">{t(`invite.customerNumbersContractsOld`)}</SubTitle>
      )}

      <RoleContractRequirements businessId={organization.businessId} roleValues={selectedRoles} />

      <StyledHeadline as="h3" size="Six">{t(`general.contractNumbers`)}</StyledHeadline>

      {((customerNumbers && customerNumbers.length > 0) ||
        (businessPartnerNumbers && businessPartnerNumbers.length > 0)) && (
        <InviteDropDown
          id="customer_number_dropdown"
          title={t(`general.selectCustomerNumbers`)}
          placeHolderText={t(`general.customerNumber`)}
          options={getCustomerNumbers()}
          selectedOptions={toDropDownOptions(
            selectedBusinessPartnerNumbers.concat(selectedCustomerNumbers),
            businessPartnerNumbers.concat(customerNumbers).sort(sortIDropDownOptionsByDisabledStatusAndValue)
          )}
          onChange={values => selectCustomerNumbers(values)}
        />
      )}
      {LSCNumbers && LSCNumbers.length > 0 && (
        <InviteDropDown
          id="lsc_number_dropdown"
          title={t(`general.selectLogisticsContractNumbers`)}
          placeHolderText={t(`general.LCSNumber`)}
          options={LSCNumbers?.sort(sortIDropDownOptionsByDisabledStatusAndValue)}
          selectedOptions={toDropDownOptions(selectedLogisticsContractNumbers, LSCNumbers)}
          onChange={values => setSelectedLSCNumbers(organization.businessId, toStringArray(values))}
        />
      )}
      {environmentStore.hasFeature('transportId') && transportIds && transportIds.length > 0 && (
        <InviteDropDown
          id="transport_id_dropdown"
          title={t(`general.selectTransportIds`)}
          placeHolderText={t(`general.transportId`)}
          options={transportIds?.sort(sortIDropDownOptionsByDisabledStatusAndValue)}
          selectedOptions={toDropDownOptions(selectedTransportIds, transportIds)}
          onChange={values => setSelectedTransportIds(organization.businessId, toStringArray(values))}
        />
      )}

      <StyledButtonsWrapper>
        <BackButton
          onClick={() => {
            userDetailsStore.setStep(1)
          }}
        ></BackButton>

        <NextButton
          customText={t(`general.makeChanges`)}
          disabled={!sendChangesPossible()}
          onClick={() => {
            const shouldRefreshToken = organizationUser.accountId === user.accountId
            userDetailsStore.postChangesToBackend(organization.businessId, shouldRefreshToken)
            userDetailsStore.setStep(3)
          }}
        />
      </StyledButtonsWrapper>
    </>
  )
}

export const EditStep2 = observer(EditStep2Component)

const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;

  @media ${breakpoint.mobile} {
    margin-top: ${({ theme }) => theme.spacing.xs}rem;
  }
`

const StyledHeadline = styled(Headline)`
  margin-top: 16px;
`
