import React, { useContext } from 'react'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next'
import { Headline } from '@postidigital/posti-components'
import { StoreContext } from '../../store'
import { RouteComponentProps, withRouter } from 'react-router'
import { observer } from 'mobx-react-lite'
import { WideCard } from './WideCard'
import { getCurrentBasePath } from '../../utils/helpers'
import { ViewLoading } from '../../components'

interface IProps {
  businessId: string
}

type Props = IProps & RouteComponentProps

export const UserDetailsComponent: React.FC<Props> = ({ businessId }) => {
  const { i18n } = useTranslation()
  const { organisationStore } = useContext(StoreContext)
  const { mainUsers, normalUsers, isLoading } = organisationStore

  const { t } = useTranslation()

  if (isLoading) {
    return <ViewLoading />
  }

  const getOrgUserPath = (organizationUserId: string): string => {
    const basePath = getCurrentBasePath(i18n)
    return `${basePath}/user/${organizationUserId}`
  }

  const renderUsers = mainUsers => {
    return mainUsers.map(user => {
      return (
        <WideCard
          to={getOrgUserPath(user.organizationUserId)}
          key={user.organizationUserId}
          title={`${user.firstName} ${user.lastName}`}
          subtitle={user.email}
        />
      )
    })
  }

  return (
    <div>
      <StyledHeadline as="div" size="Four" color="#000000">
        {t(`general.mainUsers`)}
      </StyledHeadline>
      {renderUsers(mainUsers)}
      <StyledHeadline as="div" size="Four" color="#000000">
        {t(`general.users`)}
      </StyledHeadline>
      {renderUsers(normalUsers)}
    </div>
  )
}

export const UserDetails = withRouter(observer(UserDetailsComponent))

const StyledHeadline = styled(Headline)`
  margin-top: 32px;
  margin-bottom: 16px;
`
