import React, { useContext, useState } from 'react'
import styled from 'styled-components'

import { TransProps } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'
import { observer } from 'mobx-react-lite'
import { StoreContext } from '../../store'
import { checkIfMobile } from '../../utils/breakpoint'
import { WideCard, ShowMoreButton } from '../../components'
import { getCurrentBasePath } from '../../utils/helpers'
import { useTranslation } from 'react-i18next'

type Props = Partial<TransProps> & RouteComponentProps

export const LandingUsersComponent: React.FC<Props> = () => {
  const defaultUserCount = 10
  const [visibleUsers, setvisibleUsers] = useState(defaultUserCount)
  const { i18n } = useTranslation()
  const { searchStore } = useContext(StoreContext)

  const { users } = searchStore

  const handleAddUsers = () => {
    if (checkIfMobile()) {
      return setvisibleUsers(visibleUsers + 3)
    }
    return setvisibleUsers(visibleUsers + 20)
  }

  const handleHideUsers = () => {
    return setvisibleUsers(defaultUserCount)
  }

  const renderShowButton = () => {
    if (visibleUsers < users.length) {
      return (
        <ShowMoreButton
          id="show_more_users_button"
          onClick={() => {
            handleAddUsers()
          }}
          type="showMore"
        />
      )
    }

    if (visibleUsers !== defaultUserCount) {
      return (
        <ShowMoreButton
          id="show_less_users_button"
          onClick={() => {
            handleHideUsers()
          }}
          type="showLess"
        />
      )
    }
  }

  return (
    <StyledUsersWrapper>
      {users.slice(0, visibleUsers).map(user => {
        return (
          <WideCard
            to={`${getCurrentBasePath(i18n)}/user/${user.organizationUserId}`}
            key={user.organizationUserId}
            title={`${user.firstName} ${user.lastName}`}
            subtitle={user.email}
          />
        )
      })}
      {renderShowButton()}
    </StyledUsersWrapper>
  )
}

export const LandingUsers = withRouter(observer(LandingUsersComponent))

const StyledUsersWrapper = styled('div')`
  margin-top: 24px;
`
