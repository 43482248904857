import React, { useContext, useState } from 'react'
import styled, { css } from 'styled-components'
import { TransProps, useTranslation } from 'react-i18next'
import { StoreContext } from '../../store'
import { observer } from 'mobx-react-lite'
import { MainUserRadioGroup } from '../../components'

type Props = Partial<TransProps>

const UserSectionComponent: React.FC<Props> = () => {
  const { i18n } = useTranslation()

  const { inviteStore } = useContext(StoreContext)
  const { toggleUserRole, roleValues, selectAll, toggleSelectAll } = inviteStore

  return (
    <StyledSectionWrapper>
      <MainUserRadioGroup
        isMainUser={roleValues.includes('MainUser')}
        toggleUserRole={() => toggleUserRole('MainUser')}
      />
    </StyledSectionWrapper>
  )
}

export const UserSection = observer(UserSectionComponent)

const StyledMarginRight = styled('span')`
  margin-right: 8px;
`

const StyledSectionWrapper = styled.div`
  display: flex;
  margin-bottom: 40px;
`

const StyledCheckBoxWrapper = styled('div')`
  padding-top: 1.5rem;
  margin-left: 60px;
`
