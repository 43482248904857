import React, { ReactElement } from 'react'
import { TransProps, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Dropdown, Tooltip } from '../../components'
import { InfoIcon, Body } from '@postidigital/posti-components'
import styled from 'styled-components'
import { toJS } from 'mobx'
import { DropDownType, IDropDownOption } from '../../store/dataModels/interfaces'
import { breakpoint } from '../../utils/breakpoint'

interface IProps {
  id?: string
  title: string
  placeHolderText: string
  options?: IDropDownOption[]
  selectedOptions?: IDropDownOption[]
  onChange?: (values: IDropDownOption[]) => any
  toolTipInfo?: ReactElement<any>
}

type Props = TransProps & IProps

export const InviteDropDownComponent: React.FC<Props> = ({
  id,
  title,
  placeHolderText,
  options,
  onChange,
  selectedOptions,
  toolTipInfo,
}) => {
  const [t] = useTranslation()

  // react select can not handle mobx observables, so we have to convert the array to JS
  const optionsToJs = toJS(options)
  const selectedOptionsToJs = toJS(selectedOptions)

  const allOption = {
    label: t(`general.selectAll`),
    value: '*',
    type: DropDownType.SELECT_ALL,
  }
  return (
    <>
      <StyledInputWrapper id={id || undefined}>
        <StyledLabelWrapper>
          {title && (
            <Body as="div" size="Three">
              {title}
            </Body>
          )}
          {toolTipInfo && (
            <Tooltip
              trigger={
                <span>
                  <InfoIcon height="1.5rem" />
                </span>
              }
              position="bottom center"
            >
              {toolTipInfo}
            </Tooltip>
          )}
        </StyledLabelWrapper>
        <Dropdown
          placeHolderText={placeHolderText}
          isMulti
          options={optionsToJs}
          selectedOptions={selectedOptionsToJs}
          onChange={values => onChange(values)}
          allowSelectAll
          allOption={allOption}
          closeMenuOnSelect={false}
        />
      </StyledInputWrapper>
    </>
  )
}

export const InviteDropDown = observer(InviteDropDownComponent)

const StyledInputWrapper = styled.div`
  max-width: 360px;
  @media ${breakpoint.mobile} {
    margin-bottom: 16px;
  }
`

const StyledLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 8px;
`
