import React, { useContext } from 'react'
import styled from 'styled-components'
import { TransProps, useTranslation } from 'react-i18next'
import { StoreContext } from '../../store'
import { observer } from 'mobx-react-lite'
import { Input, Button, PlusIcon, Loading } from '@postidigital/posti-components'
import { breakpoint } from '../../utils/breakpoint'
import { EmailItem } from './EmailItem'

type Props = Partial<TransProps>

const EmailsSectionComponent: React.FC<Props> = () => {
  const { t, i18n } = useTranslation()

  const { inviteStore } = useContext(StoreContext)
  const {
    email,
    setEmail,
    isValidEmail,
    emailList,
    parsedEmail,
    addEmail,
    isLoading,
    isEmailValidationConflict,
    clearPendingRequest,
  } = inviteStore
  const buttonDisabled = typeof email !== 'string' || email === '' || !isValidEmail || isLoading

  const addEmailToStore = () => {
    if (parsedEmail && isValidEmail) {
      addEmail(parsedEmail)
    }
  }
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      addEmailToStore()
    }
  }

  const emailInputMessage = isEmailValidationConflict ? t('errors.invite_email_validation_conflict.title') : null

  return (
    <>
      <StyledInputWrapper>
        <StyledInput
          value={email}
          onKeyPress={handleKeyPress}
          onChange={e => {
            setEmail(e.currentTarget.value)
            // If we are showing current error clear pending request
            if (isEmailValidationConflict) {
              clearPendingRequest()
            }
          }}
          type="email"
          label={t(`general.email`)}
          id="email"
          lightBackground
          isInvalid={!isValidEmail || isEmailValidationConflict}
          message={emailInputMessage}
        />
        <StyledButton
          css
          onClick={() => {
            addEmailToStore()
          }}
          mode="primary"
          size="sm"
          disabled={buttonDisabled}
          icon={isLoading ? Loading : null}
        >
          {t(`general.add`)}
        </StyledButton>
      </StyledInputWrapper>
      <StyledEmailsWrapper>
        {emailList.map(email => {
          return <EmailItem key={email} email={email} />
        })}
      </StyledEmailsWrapper>
    </>
  )
}

export const EmailsSection = observer(EmailsSectionComponent)

const StyledEmailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 24px;
`

const StyledInput = styled(Input)`
  width: 100%;
  margin-right: 8px;
  margin-bottom: 0;
  @media ${breakpoint.mobile} {
    margin-right: 0px:
  }
  div {
    border-radius: 4px;
  }
`

const StyledButton = styled(Button)`
  border-radius: 4px;
  margin: -3px 0;
  min-height: 78px;
  align-self: start;
  @media ${breakpoint.mobile} {
    margin-left: auto;
    margin-right: auto;
  }
  ::before {
    border-radius: 4px;
  }
`

const StyledInputWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  margin-bottom: 16px;
  @media ${breakpoint.mobile} {
    flex-wrap: wrap;
  }
`

const StyledPlusIcon = styled(PlusIcon)`
  margin-right: 8px;
`
