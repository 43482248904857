import React, { useContext } from 'react'
import styled from 'styled-components'
import { TransProps, useTranslation } from 'react-i18next'
import { StoreContext } from '../../store'
import { observer } from 'mobx-react-lite'
import { CloseIcon } from '@postidigital/posti-components'
import { IconButton } from '..'

type Props = Partial<TransProps>

const EmailItemComponent: React.FC<Props> = ({ email }) => {
  const { inviteStore } = useContext(StoreContext)
  const { t } = useTranslation()
  const label = t('invite.removeEmailButtonLabel', { email })
  return (
    <StyledWrapper>
      <StyledEmailText key={email}>{email}</StyledEmailText>
      <StyledIconButton aria-label={label} mode="secondary" onClick={() => inviteStore.removeEmail(email)}>
        <CloseIcon width="16px" height="16px" />
      </StyledIconButton>
    </StyledWrapper>
  )
}

export const EmailItem = observer(EmailItemComponent)

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px;
  background-color: ${({ theme }) => theme.color.neutralGray2};
  margin: 4px 0;
`

const StyledEmailText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.primary};

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 154%;
  color: #000000;
`

const StyledIconButton = styled((props: any) => <IconButton {...props} />)`
  font-size: 8px;
  margin-left: 8px;
  padding: 4px 2px;
  border: 0;
  cursor: pointer;
  align-self: center;
  > span {
    padding: 0;
  }
  &:focused: {
    border: 1px solid blue;
  }
  & svg:first-child,
  & img:first-child {
    margin-right: 4px !important;
  }
  & svg:last-child,
  & img:last-child {
    margin-left: 4px !important;
  }
`
