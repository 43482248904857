import React, { useContext } from 'react'
import styled from 'styled-components'
import { TransProps, useTranslation } from 'react-i18next'
import { ArrowRightIcon, Body, ProfileIcon, Text } from '@postidigital/posti-components'
import { RouteComponentProps, withRouter } from 'react-router'
import { observer } from 'mobx-react-lite'
import Theme from '@postidigital/posti-theme'
import { breakpoint } from '../../utils/breakpoint'

interface IProps {
  title: string
  to: string
  subtitle?: string
}

type Props = Partial<TransProps> & IProps & RouteComponentProps

const WideCardComponent: React.FC<Props> = ({ title, to, subtitle, history }) => {
  const handleClick = () => {
    history.push(to)
  }

  return (
    <StyledOrganisationWrapper key={title} onClick={handleClick}>
      <div>
        <StyledProfileIcon color={Theme.color.brandPurple} />
      </div>

      <div>
        <StyledBody as="div" size="Two">
          {title}
        </StyledBody>
        {subtitle && <StyledSubTitle>{subtitle}</StyledSubTitle>}
      </div>

      <MarginLeftDiv>
        <StyledArrowRightIcon width="28px" />
      </MarginLeftDiv>
    </StyledOrganisationWrapper>
  )
}

export const WideCard = withRouter(observer(WideCardComponent))

const StyledBody = styled(Body)`
  font-weight: 600;
`

const StyledTitleAndSubtitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 40%;
`

const StyledOrganisationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 80px;

  width: 100%;

  align-items: center;

  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 155.6%;
  color: ${({ theme }) => theme.color.neutralBlack};
  cursor: pointer;

  @media ${breakpoint.mobile} {
    padding: ${({ theme }) => theme.spacing.xxs}rem;
    height: 100px;
    margin-top: 8px;
  }
`

const MarginLeftDiv = styled.div`
  margin-left: auto;
`

const StyledArrowRightIcon = styled(ArrowRightIcon)`
  // margin-left: auto;
`
const StyledProfileIcon = styled(ProfileIcon)`
  padding-right: 8px;
`
const StyledSubTitle = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;

  word-break: break-all;
  color: ${({ theme }) => theme.color.neutralNetworkGray};
`
