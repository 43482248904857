import React, { useContext } from 'react'
import { TransProps, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import { Button, Headline, Body } from '@postidigital/posti-components'
import { StoreContext } from '../../store'
import styled from 'styled-components'
import Popup from 'reactjs-popup'
import Theme from '@postidigital/posti-theme'
import { StyledButton } from '..'

interface IProps {
  trigger: JSX.Element
  businessId: string
  accountId: string
  userEmail: string
}

type Props = Partial<TransProps> & IProps

const RemoveUserModalComponent: React.FC<Props> = ({ trigger, businessId, accountId, userEmail }) => {
  const [t] = useTranslation()
  const { userDetailsStore } = useContext(StoreContext)

  return (
    <>
      <Popup contentStyle={contentStyle} trigger={trigger} modal closeOnDocumentClick>
        {close => (
          <StyledModalContent>
            <StyledModalTitle as="div" size="Four" color={Theme.color.brandNavy}>
              {t(`removeModal.title`)}
            </StyledModalTitle>
            <Body size="Three">{t(`removeModal.content`)}</Body>
            <StyledButtonsWrapper>
              <StyledButton
                id="delete_modal"
                mode="secondary"
                hasBackground={false}
                onClick={async () => {
                  await userDetailsStore.removeOrganisation(businessId, accountId, userEmail)
                }}
              >
                {t(`removeModal.removeButton`)}
              </StyledButton>
              <StyledButton
                id="cancel_modal"
                backgroundColor={Theme.color.brandNavy}
                color={Theme.color.brandPink}
                onClick={() => close()}
              >
                {t(`general.cancel`)}
              </StyledButton>
            </StyledButtonsWrapper>
          </StyledModalContent>
        )}
      </Popup>
    </>
  )
}

export const RemoveUserModal = observer(RemoveUserModalComponent)

const StyledModalTitle = styled(Headline)`
  margin-bottom: ${({ theme }) => theme.spacing.sm}rem;
`

const contentStyle: React.CSSProperties = {
  maxWidth: '552px',
  alignSelf: "center",
}

const StyledModalContent = styled.div`
  padding: ${({ theme }) => theme.spacing.md}rem;
  // max-width: 552px;
`
const StyledButtonsWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.md}rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
