import React, { useState } from 'react'
import styled from 'styled-components'
import { sendUserAction } from '@postidigital/posti-google-analytics'

import { RouteComponentProps, withRouter } from 'react-router'
import {
  Body,
  CSATButtonGroup,
  FeedbackButton,
  FeedbackTerribleIcon,
  FeedbackBadIcon,
  FeedbackOkIcon,
  FeedbackGoodIcon,
  FeedbackGreatIcon,
  Headline,
} from '@postidigital/posti-components'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

interface IProps {}

type Props = RouteComponentProps & IProps

const CSATSelectorComponent: React.FC<Props> = ({}) => {
  const [sent, setSent] = useState(false)
  const [t] = useTranslation()

  const handleOnSelect = (event: MouseEvent<Element, MouseEvent>, value: number) => {
    if (sent) {
      return
    }
    setSent(true)
    // Send csat value to GA
    sendUserAction({
      actionType: 'gaveRating',
      ratingValue: `${value}`,
    })
  }

  const renderCSATSelector = () => (
    <CSATButtonGroup selectedValue={undefined} onSelect={handleOnSelect}>
      <FeedbackButton
        aria-label={t('csat.terrible.aria')}
        label={t('csat.terrible.label')}
        value="1"
        icon={FeedbackTerribleIcon}
      />
      <FeedbackButton
        aria-label={t('csat.bad.aria')}
        label={t('csat.bad.label')}
        value="2"
        icon={FeedbackBadIcon}
      />
      <FeedbackButton
        aria-label={t('csat.ok.aria')}
        label={t('csat.ok.label')}
        value="3"
        icon={FeedbackOkIcon}
      />
      <FeedbackButton
        aria-label={t('csat.good.aria')}
        label={t('csat.good.label')}
        value="4"
        icon={FeedbackGoodIcon}
      />
      <FeedbackButton
        aria-label={t('csat.great.aria')}
        label={t('csat.great.label')}
        value="5"
        icon={FeedbackGreatIcon}
      />
    </CSATButtonGroup>
  )

  return (
    <>
      <Headline as="h2" size="Six">
        {sent ? t('csat.thanks.title') : t('csat.title')}
      </Headline>
      {!sent && renderCSATSelector()}
      {sent && <Body size="Five">{t('csat.thanks.text')}</Body>}
    </>
  )
}

export const CSATSelector = withRouter(observer(CSATSelectorComponent))
