import React, { useContext } from 'react'
import { TransProps, useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'
import { observer } from 'mobx-react-lite'
import { StoreContext } from '../../store'
import { CloseButton } from '../../components'
import styled from 'styled-components'
import { Body, Notification } from '@postidigital/posti-components'
import { EditInfoHeader } from './editInfoHeader'
import { breakpoint } from '../../utils/breakpoint'

type Props = Partial<TransProps> & RouteComponentProps<{}>

const EditTopSectionComponent: React.FC<Props> = ({ history }) => {
  const [t] = useTranslation()
  const { userDetailsStore } = useContext(StoreContext)

  const { changesMade, step } = userDetailsStore

  const renderNoti = () => {
    if (step === 3) {
      return
    }

    const getNotiText = (): string => {
      if (step === 2) {
        return !changesMade ? t(`editUser.makeChanges`) : t(`editUser.pressButtonToMakeChange`)
      }
      return !changesMade ? t(`editUser.makeChanges`) : t(`editUser.complete`)
    }

    return (
      <StyledNotiWrapper>
        <Notification type={!changesMade ? 'success' : 'warning'} dismissible={false}>
          <StyledBody as="span" size="Four">
            {getNotiText()}
          </StyledBody>
        </Notification>
      </StyledNotiWrapper>
    )
  }

  return (
    <>
      <StyledButtonWrapper>
        <CloseButton onClick={() => history.goBack()} />
      </StyledButtonWrapper>
      <EditInfoHeader />
      {renderNoti()}
    </>
  )
}

export const EditTopSection = withRouter(observer(EditTopSectionComponent))

const StyledButtonWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 16px 0;
  @media ${breakpoint.mobile} {
    padding-top: 0;
  }
`

const StyledNotiWrapper = styled('div')`
  margin-top: 8px;
  margin-bottom: 40px;
`

const StyledBody = styled(Body)`
  font-weight: 600;
  margin: 0;
`
