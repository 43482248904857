import { observable, action, runInAction, computed } from 'mobx'
import { serviceAdapter } from '../service/serviceAdapter'
import { IUser } from './dataModels/interfaces'

export class UserStore {
  @observable
  user: IUser

  @observable
  isLoading: boolean = true

  @observable
  error: boolean = false

  @observable
  getRolesThatRequireContracts(businessId: string, roleValues: string[]) {
    const organization = this.user.organizations.find(org => org.businessId === businessId)
    if (!organization) {
      return []
    }
    const roleArrays = organization.roleGroups.map(roleGroup => roleGroup.roles)

    const selectedRoles = []
    roleArrays.forEach(arr => {
      arr.forEach(role => {
        if (roleValues.includes(role.value)) {
          selectedRoles.push(role)
        }
      })
    })

    return selectedRoles.filter(
      role =>
        role.customerNumberRequired ||
        role.logisticsContractNumberRequired ||
        role.transportIdRequired ||
        role.businessPartnerNumberRequired
    )
  }

  @action
  async fetchUserData() {
    this.isLoading = true
    try {
      let response = await serviceAdapter.sendGetRequest('/api/user')

      if (response.status !== 200) {
        runInAction(() => {
          this.error = true
        })
        throw new Error('Bad response from server')
      }
      const user = await response.json()

      runInAction(() => {
        this.user = user
        this.isLoading = false
      })
    } catch (err) {
      console.error(err)
    }
  }
}

export const userStore = new UserStore()
