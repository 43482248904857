import 'core-js/es/set'
import 'core-js/es/map'
import 'mobx-react-lite/batchingForReactDom'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'raf/polyfill'
import React from 'react'
import { render } from 'react-dom'
import { IndexWrapper } from './views/index'
import { ID } from './constants'

render(<IndexWrapper />, document.getElementById(ID.FEATURE))
