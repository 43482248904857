import React from 'react'
import styled, { css } from 'styled-components'
import { TransProps, useTranslation } from 'react-i18next'
import {
  Button,
  ArrowRightIcon,
  ArrowLeftIcon,
  PlusIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Loading,
  EditIcon,
  CloseIcon,
  KnobsIcon,
} from '@postidigital/posti-components'
import Theme from '@postidigital/posti-theme'

interface IProps {
  onClick?: () => any
  disabled?: boolean
  loading?: boolean
  customText?: string
  id?: string
}

type Props = Partial<TransProps> & IProps

export const NextButton: React.FC<Props> = ({ onClick, disabled, loading, customText }) => {
  const [t] = useTranslation()

  return (
    <>
      <StyledButton id="next_button" css disabled={disabled} size="sm" onClick={() => onClick && onClick()}>
        <StyledButtonTitle>{customText ? customText : t(`general.next`)}</StyledButtonTitle>
        {loading ? (
          <StyledLoading color={Theme.color.neutralWhite} size="sm" />
        ) : (
          <StyledArrowRightIcon
            color={!disabled ? Theme.color.neutralWhite : Theme.color.neutralPassiveGray}
            height="1em"
            width="1em"
          />
        )}
      </StyledButton>
    </>
  )
}

const StyledButtonTitle = styled('span')`
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
`

export const StyledButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  width: max-content;
  height: 48px;
  border-radius: 4px;
  ::before {
    border-radius: 4px;
  }
`

const StyledArrowRightIcon = styled(ArrowRightIcon)`
  margin-left: 16px;
`

const StyledLoading = styled(Loading)`
  margin-left: 16px;
`

export const BackButton: React.FC<Props> = ({ onClick, disabled }) => {
  const [t] = useTranslation()

  return (
    <>
      <StyledButton
        id="back_button"
        mode="secondary"
        hasBackground={false}
        css
        disabled={disabled}
        size="sm"
        onClick={() => onClick && onClick()}
      >
        <StyledArrowLeftIcon
          color={!disabled ? Theme.color.signalBlue : Theme.color.neutralPassiveGray}
          height="1em"
          width="1em"
        />
        <StyledButtonTitle>{t(`general.back`)}</StyledButtonTitle>
      </StyledButton>
    </>
  )
}

const StyledArrowLeftIcon = styled(ArrowLeftIcon)`
  margin-right: 16px;
`

export const AddAnotherButton: React.FC<Props> = ({ onClick, disabled }) => {
  const [t] = useTranslation()

  return (
    <>
      <StyledButton
        id="another_button"
        mode="secondary"
        hasBackground={false}
        css
        disabled={disabled}
        size="sm"
        onClick={() => onClick && onClick()}
      >
        <StyledPlusIcon
          color={!disabled ? Theme.color.signalBlue : Theme.color.neutralPassiveGray}
          height="1em"
          width="1em"
        />
        <StyledButtonTitle>{t(`invite.sendAnotherInvite`)}</StyledButtonTitle>
      </StyledButton>
    </>
  )
}

const StyledPlusIcon = styled(PlusIcon)`
  margin-right: 16px;
`

export const ReadyButton: React.FC<Props> = ({ onClick, disabled }) => {
  const [t] = useTranslation()

  return (
    <>
      <StyledReadyButton id="ready_button" css disabled={disabled} size="sm" onClick={() => onClick && onClick()}>
        <StyledButtonTitle>{t(`general.ready`)}</StyledButtonTitle>
      </StyledReadyButton>
    </>
  )
}

const StyledReadyButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  width: max-content;
  height: 48px;
  margin-left: 24px;
  ::before {
    border-radius: 4px;
  }
`

interface IShowMoreProps {
  id?: string
  type: 'showMore' | 'showLess'
  onClick: () => void
}

export const ShowMoreButton: React.FC<IShowMoreProps> = ({ type, onClick, id }) => {
  const [t] = useTranslation()
  return (
    <StyledShowMoreText
      id={id || 'show_more_button'}
      onClick={() => {
        onClick()
      }}
    >
      {type === 'showMore' ? t(`general.showMore`) : t(`general.showLess`)}
      {type === 'showMore' ? (
        <ChevronDownIcon color={Theme.color.signalBlue} height="1.5em" width="1.5em" />
      ) : (
        <ChevronUpIcon color={Theme.color.signalBlue} height="1.5em" width="1.5em" />
      )}
    </StyledShowMoreText>
  )
}

const StyledShowMoreText = styled.div(
  ({ theme }) => css`
    font-family: ${theme.fontFamily.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 155.6%;
    color: ${theme.color.signalBlue};

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 100%;
  `
)

export const EditButton: React.FC<Props> = ({ onClick, disabled, id }) => {
  const [t] = useTranslation()

  return (
    <>
      <StyledButton
        id={id ? id : 'edit_button'}
        mode="secondary"
        hasBackground={false}
        css
        disabled={disabled}
        size="sm"
        onClick={() => onClick && onClick()}
      >
        <StyledEditIcon
          color={!disabled ? Theme.color.signalBlue : Theme.color.neutralPassiveGray}
          height="1em"
          width="1em"
        />
        <StyledButtonTitle>{t(`general.edit`)}</StyledButtonTitle>
      </StyledButton>
    </>
  )
}

const StyledEditIcon = styled(EditIcon)`
  margin-right: 8px;
`

export const CloseButton: React.FC<Props> = ({ onClick, disabled }) => {
  const [t] = useTranslation()

  return (
    <>
      <StyledButton
        id="close_button"
        css
        mode="secondary"
        hasBackground={false}
        disabled={disabled}
        size="sm"
        onClick={() => onClick && onClick()}
      >
        <StyledButtonTitle>{t(`general.close`)}</StyledButtonTitle>

        <StyledCloseIcon
          color={!disabled ? Theme.color.signalBlue : Theme.color.neutralPassiveGray}
          height="1em"
          width="1em"
        />
      </StyledButton>
    </>
  )
}

const StyledCloseIcon = styled(CloseIcon)`
  margin-left: 16px;
`

export const RemoveButton: React.FC<Props> = ({ onClick, disabled, id }) => {
  const [t] = useTranslation()

  return (
    <>
      <StyledButton
        id={id ? id : 'remove_button'}
        mode="secondary"
        hasBackground={false}
        css
        disabled={disabled}
        size="sm"
        onClick={() => onClick && onClick()}
      >
        <StyledCloseIcon2
          color={!disabled ? Theme.color.signalBlue : Theme.color.neutralPassiveGray}
          height="1em"
          width="1em"
        />
        <StyledButtonTitle>{t(`removeModal.removeButton`)}</StyledButtonTitle>
      </StyledButton>
    </>
  )
}

const StyledCloseIcon2 = styled(CloseIcon)`
  margin-right: 8px;
`

const IconButtonComponent = (props: React.ButtonHTMLAttributes<HTMLButtonElement> & { mode?: string }) => {
  // Wrap all textual children to spans, to make the css rules for icons work (first-child, last-child)
  const wrappedChildren = React.Children.map(props.children, child => {
    return typeof child === 'string' ? <span>{child}</span> : child
  })

  return <Button {...(props as any)}>{wrappedChildren}</Button>
}

export const IconButton = styled(IconButtonComponent)`
  ${props => {
    if (props['aria-disabled'] || props['disabled']) {
      return `
        color: ${Theme.color.neutralPassiveGray};
        background-color: ${Theme.color.neutralGray5};
        &:before { background-color: ${Theme.color.neutralGray5}; }
      `
    } else if (props['mode'] == 'primary') {
      return `color: ${Theme.color.neutralWhite};`
    } else {
      return `color: ${Theme.color.signalBlue};`
    }
  }}

  // Overwrite all path fill-colors of the svg,
  // otherwise the above recoloring will not work
  & svg path {
    fill: currentcolor;
  }

  & svg:first-child,
  & img:first-child {
    margin-right: 12px;
  }
  & svg:last-child,
  & img:last-child {
    margin-left: 12px;
  }
`

export const FilterButton: React.FC<Props> = (
  { selected, onClick }: React.ButtonHTMLAttributes<HTMLButtonElement> & { selected: boolean },
  ref
) => {
  const { t } = useTranslation()
  return (
    <StyledFilterButton onClick={onClick} mode={selected ? 'primary' : 'secondary'} hasBackground={false}>
      <StyledKnobsIcon
        color={selected ? Theme.color.neutralWhite : Theme.color.neutralIconGray}
        width="24px"
        height="24px"
      />
      {t('filter.filter')}
    </StyledFilterButton>
  )
}

const StyledKnobsIcon = styled(KnobsIcon)`
  margin-right: 0.5rem;
`

const StyledFilterButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  width: max-content;
  height: 48px;
  margin: 24px 0.5rem 0 0.5rem;
  ${props => (props.mode === 'primary' ? `background-color: ${Theme.color.brandPetrol};` : '')}
  &::before,
  &:focus,
  &:focus::before,
  &:hover,
  &:hover::before {
    ${props => (props.mode === 'primary' ? `background-color: ${Theme.color.brandPetrol};` : '')}
  }
  span {
    font-size: 0.875rem;
    font-weight: 500;
    color: ${props => (props.mode === 'primary' ? Theme.color.neutralWhite : Theme.color.neutralNetworkGray)};
  }
`

export const DoneButton: React.FC<Props> = ({ onClick, disabled, customText }) => {
  const [t] = useTranslation()

  return (
    <>
      <StyledButton id="done_button" css disabled={disabled} size="sm" onClick={() => onClick && onClick()}>
        <StyledButtonTitle>{customText ? customText : t(`general.done`)}</StyledButtonTitle>
      </StyledButton>
    </>
  )
}